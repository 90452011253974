import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';
import { getFormData } from 'src/common/utils';

export const ProfileApi = {
  getMyProfile: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.MY_PROFILE);
      return data;
    } catch (error) {
      // throw error;
    }
  },
  followProfile: async (id: number, follow: boolean) => {
    try {
      const { data }: AxiosResponse = follow
        ? await appAxios.post(API_ROUTES.POSTS_USER_FOLLOW(id))
        : await appAxios.delete(API_ROUTES.POSTS_USER_FOLLOW(id));
      return data && data.code === 200 ? data.data : false;
    } catch (e) {
      //throw err;
    }
  },
  editImgCover: async (img: any, type: string) => {
    const formData = new FormData();
    formData.append(`user[${type}]`, img);
    try {
      const { data }: AxiosResponse = await appAxios.put(API_ROUTES.UPDATE_PROFILE, formData);
      return data ? data : false;
    } catch (error) {
      console.log(error);
    }
  },
  getPointReceive: async (startDate: string, endDate: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format()
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(
        API_ROUTES.POSTS_STATISTICS_RECEIVE_POINTS,
        {
          params
        }
      );
      return data ? data.data.period : false;
    } catch (error) {
      console.log(error);
    }
  },
  getTotalPost: async (startDate: string, endDate: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format()
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_STATISTICS_NEWS, {
        params
      });
      return data ? data.data.period : false;
    } catch (error) {
      console.log(error);
    }
  },
  getTopGivePoint: async (startDate: string, endDate: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format()
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_GIVERS_BY_USER, {
        params
      });
      return data ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  getTopReceivePoint: async (startDate: string, endDate: string) => {
    const params = {
      date_start: dayjs(startDate, 'YYYY-MM-DD').format(),
      date_end: dayjs(endDate, 'YYYY-MM-DD').format()
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_RECEIVERS_BY_USER, {
        params
      });
      return data ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  getOtherProfile: async (id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.OTHER_PROFILE(id));
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  editInforUser: async (data) => {
    const formUserEdit = getFormData(data);
    try {
      const { data }: AxiosResponse = await appAxios.put(API_ROUTES.UPDATE_PROFILE, formUserEdit);
      return data ? data : false;
    } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.errors);
      }
    }
  }
};
