import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const DashboardAPI = {
  // eslint-disable-next-line  @typescript-eslint/ban-types
  getNewestPost: async (): Promise<{}> => {
    try {
      const { data } = await appAxios.get(API_ROUTES.POSTS_DASHBOARD_NEWEST);
      return data;
    } catch (e) {
      // throw error;
    }
  }
};
