import { actionTypes as actionTypesEdit } from 'src/activities/status/statusAction';
import { IurlBase64 } from 'src/interface/IImageCmtState';
import { actionTypes } from './imageCommentAction';

const initialState = {
  fileImg: [],
  urlBase64: [],
  idImg: -999,
  closeImg: false,
  isOpenImages: false,
  imageComment: [],
  base64Comment: null,
  saveIdExists: [],
  isOpenImagesEdit: false,
  enoughImagesEdit: false,
  idImgEditExist: 0
};
export function imageCommentReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.IMPORT_IMG_COMMENT_UPLOAD: {
      const enoughImages = state.urlBase64.length > 4 ? true : false;
      return {
        ...state,
        closeImg: enoughImages
      };
    }
    case actionTypes.SAVE_IMG_COMMENT_UPLOAD: {
      const imagesBase64 = action.newFiled.map((item: any, index: number) => {
        const objectImages = {
          url: item.url,
          id: index + state.urlBase64.length
        };
        return objectImages;
      });

      const newImagesFile = action.fileImg.map((item: any, index: number) => {
        const objectImages = {
          url: item.url,
          id: index + state.fileImg.length
        };
        return objectImages;
      });

      const base64 =
        state.urlBase64.concat(imagesBase64).length > 5
          ? state.urlBase64.concat(imagesBase64).slice(0, 5)
          : state.urlBase64.concat(imagesBase64);

      const fileImg =
        state.fileImg.concat(newImagesFile).length > 5
          ? state.fileImg.concat(newImagesFile).slice(0, 5)
          : state.fileImg.concat(newImagesFile);

      const closeImg = fileImg.length >= 5 ? true : false;
      return {
        ...state,
        fileImg: fileImg,
        urlBase64: base64,
        idImg: action.id,
        closeImg: closeImg,
        isOpenImages: true,
        isOpenImagesEdit: true,
        enoughImagesEdit: base64.length > 4 ? true : false
      };
    }
    case actionTypes.CLOSE_IMG: {
      const imagesData = state.urlBase64.filter(
        (value: IurlBase64) => value.id !== action.imagesId
      );
      const fileImg = state.fileImg.filter((value: IurlBase64) => value.id !== action.imagesId);
      const idImgExists = state.saveIdExists.filter(
        (value: IurlBase64) => value !== action.imagesId
      );
      return {
        ...state,
        fileImg: fileImg,
        urlBase64: imagesData,
        closeImg: false,
        isOpenImages: imagesData && imagesData.length === 0 ? false : true,
        saveIdExists: idImgExists,
        enoughImagesEdit: false,
        isOpenImagesEdit: imagesData && imagesData.length === 0 ? false : true
      };
    }
    case actionTypes.DELETE_IMAGE: {
      return {
        ...state,
        urlBase64: [],
        fileImg: [],
        isOpenImages: false,
        idImg: -999,
        imageComment: [],
        base64Comment: null,
        idImgEditExist: 0
      };
    }
    case actionTypes.IS_CHOOSING_IMAGE: {
      return {
        ...state,
        imageComment: action.fileImg,
        base64Comment: action.newFiled && action.newFiled[0].url,
        idImg: action.id
      };
    }
    case actionTypesEdit.GET_STATUS_BY_ID_SUCCESS: {
      return {
        ...state,
        urlBase64: action.payload.images,
        saveIdExists: action.payload.images && action.payload.images.map((img: any) => img.id),
        fileImg:
          action.payload.images &&
          action.payload.images.map((item: any) => {
            const objectImages = {
              url: item.url,
              id: item.id
            };
            return objectImages;
          }),
        isOpenImagesEdit: action.payload.images.length > 0 ? true : false,
        enoughImagesEdit: action.payload.images && action.payload.images.length > 4 ? true : false
      };
    }
    case actionTypesEdit.CLOSE_MODAL: {
      return { ...state, urlBase64: [], isOpenImagesEdit: false };
    }
    case actionTypes.IMPORT_EDIT_IMG_COMMENT_UPLOAD: {
      return {
        ...state,
        base64Comment: action.url && action.url,
        idImg: action.id,
        idImgEditExist: action.idExist
      };
    }
    default:
      return state;
  }
}
