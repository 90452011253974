import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';
import { IeventState } from 'src/interface/IEventState';

export const EventAPI = {
  getListDataEvent: async (): Promise<IeventState> => {
    try {
      const data: IeventState = await appAxios.get(API_ROUTES.BANNERS);
      return data;
    } catch (e) {
      // throw error;
    }
  }
};
