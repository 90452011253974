import { put, takeLatest, call, all } from 'redux-saga/effects';

import { ActivityHistoryApi } from 'src/api/ActivityHistoryApi';
import { IactionTypeState } from 'src/interface/IActionTypeState';
import { IActivityHistoryResponse } from 'src/interface/IActivityHistoryState';
import { actionTypes } from './activityHistoryAction';

function* getListActivityHistory(action: IactionTypeState): any {
  const { page, limit } = action;
  try {
    const data: IActivityHistoryResponse = yield call(ActivityHistoryApi.gets, page, limit);
    if (data) {
      yield put({
        type: actionTypes.GET_LIST_ACTIVITY_HISTORY_SUCCESS,
        payload: data
      });
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIST_ACTIVITY_HISTORY_ERROR
    });
  }
}

function* watchRequestGetListActivityHistory() {
  yield takeLatest(actionTypes.GET_LIST_ACTIVITY_HISTORY_REQUEST, getListActivityHistory);
}

export function* activityHistorySaga() {
  yield all([watchRequestGetListActivityHistory()]);
}
