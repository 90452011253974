export const actionTypes = {
  GET_LIST_POINT_REWARD_REQUEST: 'GET_LIST_POINT_REWARD_REQUEST',
  GET_LIST_POINT_REWARD_SUCESS: 'GET_LIST_POINT_REWARD_SUCESS',
  GET_LIST_POINT_REWARD_ERROR: 'GET_LIST_POINT_REWARD_ERROR',
  SET_PAGE_LIST_POINT_REWARD: 'SET_PAGE_LIST_POINT_REWARD'
};

export const requestListPointReward = (page: number, limit: number) => ({
  type: actionTypes.GET_LIST_POINT_REWARD_REQUEST,
  page,
  limit
});
