import { AxiosResponse, AxiosError } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';
import { IDataPoll } from 'src/interface/IPollState';

export const PollApi = {
  postPoll: async (action: { type: string; payload: IDataPoll }): Promise<AxiosResponse<any>> => {
    const { payload } = action;
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POLL_LIST, {
        ...payload
      });
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getPollDetail: async (id: string): Promise<AxiosResponse<any>> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POLL_DETAIL(id));
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getCurrentPolls: async (): Promise<AxiosResponse<any>> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POLL_LIST);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  postSubmitPoll: async (
    id: string | number,
    answer: {
      option_ids: number[];
      other_content?: string;
    }
  ): Promise<AxiosResponse<any>> => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POLL_SUBMIT(id), answer);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  }
};
