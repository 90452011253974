import { AxiosResponse } from 'axios';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { chartApi } from 'src/api/chartAPIService';
import {
  actionTypes,
  getListProject,
  getDataHeatMap,
  getDateCurrent
} from 'src/components/Chart/heatmapAction';

function* requestListProjectSaga() {
  try {
    const data: AxiosResponse = yield call(chartApi.getListProject);
    yield put(getListProject(data));
  } catch (error) {
    // console.log(error)
  }
}

function* requestDataHeatmapSaga(action: any) {
  const params = action.data;
  try {
    const data: AxiosResponse = yield call(chartApi.getDataHeatmap, params);
    yield put(getDataHeatMap(data));
    yield put(getDateCurrent(params));
  } catch (error) {
    // console.log(error)
  }
}

function* watchRequestListProject() {
  yield takeLatest(actionTypes.REQUEST_LIST_PROJECT, requestListProjectSaga);
}
function* watchRequestDataHeatmap() {
  yield takeLatest(actionTypes.REQUEST_DATA_HEATMAP, requestDataHeatmapSaga);
}

export function* chartSaga() {
  yield all([watchRequestListProject(), watchRequestDataHeatmap()]);
}
