import APP_ROUTES from 'src/common/appRoutes';
import { IobjAny, InewsResponse } from 'src/interface/INewsState';
import { actionTypes } from './newsAction';

const initialState = {
  data: {
    news: [],
    count: 0,
    page: 0,
    limit: 0
  },
  prePathname: APP_ROUTES.HOME,
  isLoading: true,
  actionSuccess: true //need this variable because the data variable too long for check
};

export function newsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.REQUEST_DATA_LIST_NEWS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_DATA_LIST_NEWS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
        actionSuccess: true
      };
    }
    case actionTypes.GET_DATA_LIST_NEWS_NEXT_PAGE: {
      const news = [...state.data.news, ...action.data.news];

      let data: InewsResponse = Object.assign({}, state.data);
      data = Object.assign(data, action.data);
      data.news = news;

      return {
        ...state,
        data,
        isLoading: false,
        actionSuccess: true
      };
    }
    case actionTypes.GET_DATA_LIST_NEWS_FALL: {
      return {
        ...state,
        data: {},
        isLoading: false,
        actionSuccess: false
      };
    }
    case actionTypes.REMOVE_ITEM_BOOK_MARK: {
      const news = state.data.news.filter(function (obj: IobjAny) {
        return obj.id.toString() !== action.idPost.toString();
      });

      return {
        ...state,
        data: {
          ...state.data,
          news
        }
      };
    }
    default:
      return state;
  }
}
