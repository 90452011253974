import Router from 'next/router';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { treeChartAPI } from 'src/api/treeChartAPI';
import APP_ROUTES from 'src/common/appRoutes';
import { TreeChartResponse } from 'src/interface/ITreeNode';
import { actionTypes } from './treeChartAction';

function* requestGetUsersList() {
  try {
    const data: TreeChartResponse = yield call(treeChartAPI.getListUsers);
    yield put({ type: actionTypes.GET_LIST_USERS_SUCCESS, payload: data.items });
  } catch (e) {
    console.log(e);
  }
}

function* requestGetUsersCmsList(action: any) {
  const { params } = action;
  try {
    const data: TreeChartResponse = yield call(treeChartAPI.getListUsersCms, params);
    yield put({ type: actionTypes.GET_LIST_USERS_CMS_SUCCESS, payload: data.items });
  } catch (e) {
    console.log(e);
  }
}

function* requestGetApproverChart(action: any) {
  const { params } = action;
  try {
    const data: any = yield call(treeChartAPI.getApproverChart, params);
    yield put({ type: actionTypes.GET_APPROVER_CHART_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
}

function* requestGetChartCategories() {
  try {
    const data: any = yield call(treeChartAPI.getChartCategories);
    yield put({ type: actionTypes.GET_CHART_CATEGORIES_SUCCESS, payload: data.items });
  } catch (e) {
    console.log(e);
  }
}

function* requestGetChart(action: any) {
  const { params } = action;
  try {
    const data: any = yield call(treeChartAPI.getChart, params);
    yield put({ type: actionTypes.GET_CHART_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
}

function* requestGetListCharts(action: any) {
  const { params } = action;
  try {
    const data: any = yield call(treeChartAPI.getListCharts, params);
    yield put({ type: actionTypes.GET_LIST_CHARTS_SUCCESS, payload: data.items });
    if (data.items.length > 0 && data.items[0].is_published) {
      const asPath = Router.asPath.split('/');
      if (asPath.length === 3) {
        Router.push(`${APP_ROUTES.ORG_CHART}/${asPath[asPath.length - 1]}/${data.items[0].id}`);
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestGetChartDetail(action: any) {
  const { params } = action;
  try {
    yield put({ type: actionTypes.ACTIVATE_LOADING_REQUEST });
    const data: any = yield call(treeChartAPI.getChartDetail, params);
    yield put({ type: actionTypes.GET_CHART_DETAIL_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
  }
}

function* requestGetListNodeChildren(action: any) {
  const { params } = action;
  try {
    const { data } = yield call(treeChartAPI.getListNodeChildren, params);
    yield put({ type: actionTypes.GET_NODE_CHILDREN_SUCCESS, payload: data.items });
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestGetUsersList() {
  yield takeEvery(actionTypes.GET_LIST_USERS_REQUEST, requestGetUsersList);
}

function* watchRequestGetUsersCmsList() {
  yield takeEvery(actionTypes.GET_LIST_USERS_CMS_REQUEST, requestGetUsersCmsList);
}

function* watchRequestGetApproverChart() {
  yield takeEvery(actionTypes.GET_APPROVER_CHART_REQUEST, requestGetApproverChart);
}

function* watchRequestGetChartCategories() {
  yield takeEvery(actionTypes.GET_CHART_CATEGORIES_REQUEST, requestGetChartCategories);
}

function* watchRequestGetListCharts() {
  yield takeEvery(actionTypes.GET_LIST_CHARTS_REQUEST, requestGetListCharts);
}

function* watchRequestGetChart() {
  yield takeEvery(actionTypes.GET_CHART_REQUEST, requestGetChart);
}

function* watchRequestGetChartDetail() {
  yield takeEvery(actionTypes.GET_CHART_DETAIL_REQUEST, requestGetChartDetail);
}

function* watchRequestGetListNodeChildren() {
  yield takeEvery(actionTypes.GET_NODE_CHILDREN_REQUEST, requestGetListNodeChildren);
}

export function* treeChartSaga() {
  yield all([
    watchRequestGetUsersList(),
    watchRequestGetUsersCmsList(),
    watchRequestGetApproverChart(),
    watchRequestGetListCharts(),
    watchRequestGetChart(),
    watchRequestGetChartDetail(),
    watchRequestGetChartCategories(),
    watchRequestGetListNodeChildren()
  ]);
}
