import message from 'antd/lib/message';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { actionTypes } from 'src/activities/poll/pollAction';
import { PollApi } from 'src/api/Poll';
import { IDataPoll } from 'src/interface/IPollState';

function* postPollRequest(pollData: any) {
  try {
    yield call(PollApi.postPoll, pollData);
    yield put({ type: actionTypes.POST_POLL_SUCCESS });

    const search = '';
    yield put({ type: actionTypes.GET_CURRENT_POLLS, search });
    message.success('Successful Poll request');
  } catch (error) {
    yield put({
      type: actionTypes.POST_POLL_ERROR,
      payload: 'Error'
    });
  }
}

function* getCurrentPollsRequest() {
  try {
    const data: IDataPoll[] = yield call(PollApi.getCurrentPolls);
    yield put({
      type: actionTypes.GET_CURRENT_POLLS_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_CURRENT_POLLS_ERROR,
      payload: 'Error'
    });
  }
}

function* postSubmitVotePollRequest(action: any) {
  const {
    payload: { id, answer }
  } = action;
  try {
    yield call(PollApi.postSubmitPoll, id, answer);
    message.success('Your vote has been submitted');
    yield put({
      type: actionTypes.GET_POLL_DETAIL,
      payload: { id }
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_POLL_DETAIL_ERRROR,
      id
    });
  }
}

function* getPollDetailRequest(action: any) {
  const {
    payload: { id }
  } = action;
  try {
    const data: IDataPoll = yield call(PollApi.getPollDetail, id);
    yield put({
      type: actionTypes.GET_POLL_DETAIL_SUCCESS,
      payload: data,
      id
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_POLL_DETAIL_ERRROR,
      id
    });
  }
}

function* watchPostPollRequest() {
  yield takeLatest(actionTypes.POST_POLL, postPollRequest);
}
function* watchGetCurrentPollsRequest() {
  yield takeLatest(actionTypes.GET_CURRENT_POLLS, getCurrentPollsRequest);
}

function* watchGetPollDetailRequest() {
  yield takeLatest(actionTypes.GET_POLL_DETAIL, getPollDetailRequest);
}

function* watchpPostSubmitVotePollRequestRequest() {
  yield takeLatest(actionTypes.POST_SUMMIT_POLL, postSubmitVotePollRequest);
}

export function* pollSaga() {
  yield all([
    watchPostPollRequest(),
    watchGetPollDetailRequest(),
    watchGetCurrentPollsRequest(),
    watchpPostSubmitVotePollRequestRequest()
  ]);
}
