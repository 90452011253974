import { actionTypes } from './rewardAction';

const initialState = {
  listRewards: {},
  isLoadingListRewards: false,
  actionSuccess: true,
  isLoading: true,
  isErr: false,
  listReward: [],
  isReDeem: 0, // 0 = don't action, 1 = success, -1 = fall
  listReDeem: [],
  categories: []
};

export function rewardsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_REWARD_REQUEST: {
      return { ...state, isLoadingListRewards: true };
    }
    case actionTypes.GET_LIST_REWARD_SUCCESS: {
      const newData = {
        rewards: action.payload.data.rewards,
        count: action.payload.data.rewards.length,
        limit: action.payload.data.rewards.length + 1,
        page: 1,
        originalRewards: action.payload.data.rewards,
        type: 0
      };
      return {
        ...state,
        listRewards: newData,
        isLoadingListRewards: false,
        actionSuccess: true
      };
    }
    case actionTypes.REQUEST_DATA_REWARD_DETAIL: {
      return {
        ...state,
        isLoading: true,
        isErr: false
      };
    }
    case actionTypes.GET_DATA_REWARD_DETAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isErr: false,
        listReward: action.data
      };
    }
    case actionTypes.GET_DATA_REWARD_DETAIL_FAIL: {
      return {
        ...state,
        isLoading: false,
        isErr: true,
        listReward: []
      };
    }
    case actionTypes.POST_REDEEM_REWARD_DETAIL: {
      return {
        ...state,
        isReDeem: 0
      };
    }
    case actionTypes.POST_REDEEM_REWARD_DETAIL_SUCCESS: {
      return {
        ...state,
        isReDeem: 1,
        listReDeem: state.listReDeem.concat(action.payload)
      };
    }
    case actionTypes.POST_REDEEM_REWARD_DETAIL_FALL: {
      return {
        ...state,
        isReDeem: -1
      };
    }
    case actionTypes.FILTER_LIST_REWARD_SUCCESS: {
      return {
        ...state,
        listRewards: {
          ...state.listRewards,
          rewards: action.payload
        }
      };
    }

    case actionTypes.GET_LIST_CATEGORY_REWARD_SUCCESS: {
      return {
        ...state,
        categories: action.payload
      };
    }
    case actionTypes.CHANGE_FILTER_CATEGORY: {
      return {
        ...state,
        listRewards: {
          ...state.listRewards,
          type: action.payload
        }
      };
    }
    case actionTypes.FILTER_CATEGORY_REWARD_SUCCESS: {
      return {
        ...state,
        listRewards: {
          ...state.listRewards,
          rewards: action.payload
        }
      };
    }
    default:
      return state;
  }
}
