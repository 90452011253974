import { CommonState } from 'src/interface/ICommonState';
import { actionTypes } from './commonAction';

const initCommon: CommonState = {
  isSuccess: false,
  isLoading: false,
  isError: false,
  breadcrumb: []
};

const commonReducer = (state: CommonState = initCommon, action: any) => {
  switch (action.type) {
    case actionTypes.SET_ERROR: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorMessage: action.message
      };
    }
    case actionTypes.RESET_ERROR: {
      return {
        ...state,
        isError: false,
        errorMessage: null
      };
    }
    case actionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false
      };
    }
    case actionTypes.SET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false
      };
    }
    case actionTypes.SET_BREADCRUMB: {
      return {
        ...state,
        breadcrumb: action.breadcrumb
      };
    }
    case actionTypes.SET_NO_LOADING: {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: false
      };
    }
    default:
      return state;
  }
};

export default commonReducer;
