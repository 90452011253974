import { AxiosResponse } from 'axios';
import get from 'lodash/get';
import API_ROUTES from 'src/common/apiRoutes';
import { appAxios } from './appAxios';

export const chartApi = {
  getListProject: async (): Promise<AxiosResponse<any>> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.HEATMAP_PROJECTS);
      return get(data, 'data.projects', []);
    } catch (error) {
      // console.log(error);
    }
  },
  getDataHeatmap: async (params: {
    from_date: string;
    to_date: string;
  }): Promise<AxiosResponse<any>> => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.HEATMAP_RECORDS, { params });
      return get(data, 'data.records', []);
    } catch (error) {
      // console.log(error);
    }
  }
};
