import { AxiosResponse } from 'axios';
import API_ROUTES from 'src/common/apiRoutes';
import { appAxios } from './appAxios';

export const ActivityHistoryApi = {
  // getList Activity History
  gets: async (page: number, limit: number) => {
    try {
      const params = {
        page,
        limit
      };

      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.ACTIVITY_HISTORY, {
        params
      });

      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // throw error;
    }
  }
};
