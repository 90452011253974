import { AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';
import { BOOK_MARK } from 'src/common/constants';
import { IrequestData } from 'src/interface/IAnnouncementsState';

export const AnnouncementsApi = {
  getListAnnouncements: async (requestData: IrequestData) => {
    try {
      const { page, keyword } = requestData;

      const paramKeywork = keyword
        ? keyword === BOOK_MARK
          ? { bookmark: true }
          : { keyword }
        : {};

      const paramPage = page ? { page, limit: 6 } : {};

      const params = {
        ...paramPage,
        ...paramKeywork
      };
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_ANNOUNCEMENTS, {
        params
      });
      return data && data.code === 200 && data.data.announcements ? data.data : false;
    } catch (error) {
      // throw error;
    }
  }
};
