import { all, put, takeLatest } from 'redux-saga/effects';
import { getBase64 } from 'src/common/utils';
import { actionTypes } from './imageCommentAction';

function* requestSaveMultipleImages(action: any) {
  try {
    const { file, id, isComment } = action;
    let newFiled = [];
    const newFile = file.length > 5 ? file.slice(0, 5) : file;
    const fileImg = [];
    for (let i = 0; i < file.length; i++) {
      const url = yield getBase64(file[i].originFileObj);
      const objectImages = {
        url
      };
      newFiled.push(objectImages);
    }
    newFiled = newFiled.length > 5 ? newFiled.slice(0, 5) : newFiled;
    for (const i in newFile) {
      const objectImages = {
        url: newFile[i]
      };
      fileImg.push(objectImages);
    }
    if (isComment) {
      yield put({
        type: actionTypes.IS_CHOOSING_IMAGE,
        fileImg,
        newFiled,
        id: id || 0
      });
    } else {
      yield put({
        type: actionTypes.SAVE_IMG_COMMENT_UPLOAD,
        fileImg,
        newFiled
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchImageEditCommentSave() {
  yield takeLatest(actionTypes.IMPORT_IMG_COMMENT_UPLOAD, requestSaveMultipleImages);
}
export function* imageCommentSaga() {
  yield all([watchImageEditCommentSave()]);
}
