import { AxiosError, AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const PortalAPI = {
  getListPortal: async (searchName: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.PORTALS, {
        params: {
          name: searchName
        }
      });
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListPortalRecents: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.PORTALS_RECENTS);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  favoritePortalApp: async (params: { id: number }) => {
    try {
      const response: AxiosResponse = await appAxios.put(API_ROUTES.FAVORITE_PORTAL(params.id));
      return response;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  unfavoritePortalApp: async (params: { id: number }) => {
    try {
      const response: AxiosResponse = await appAxios.put(API_ROUTES.UNFAVORITE_PORTAL(params.id));
      return response;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  trackUsage: async (params: { id: number }) => {
    try {
      const response: AxiosResponse = await appAxios.put(API_ROUTES.TRACK_USAGE(params.id));
      return response;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  }
};
