import dayjs from 'dayjs';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';
import { ILateOffDayState } from 'src/interface/ILateOffDay';

export const LateOffDayAPI = {
  getLateOffDay: async (): Promise<ILateOffDayState> => {
    try {
      const today = dayjs().format('YYYY-MM-DD');
      const params = {
        time: today,
        fields: 'edited,editing',
        scope: 'all'
      };

      const data: ILateOffDayState = await appAxios.get(API_ROUTES.OFFWORKS, { params });
      return data;
    } catch (e) {
      // throw error;
    }
  }
};
