import { put, takeLatest, call, all } from 'redux-saga/effects';
import { StatusApi } from 'src/api/StatusApi';
import { IcommentResonsive } from 'src/interface/ICommentStatusState';
import { actionTypes } from './commentStatusAction';
function* getListStatusComment(action: any): any {
  const { id, page } = action;
  try {
    const data: IcommentResonsive = yield call(StatusApi.getListComment, Number(id), Number(page));
    if (data) {
      yield (data.id = id);
      if (page > 1) {
        yield put({
          type: actionTypes.GET_LIST_COMMENT_STATUS_SUCCESS,
          payload: data
        });
      } else {
        yield put({
          type: actionTypes.GET_LIST_INITIAL_COMMENT_STATUS_SUCCESS,
          payload: data
        });
      }
    } else {
      yield put({
        type: actionTypes.GET_LIST_COMMENT_STATUS_FALL
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.GET_LIST_COMMENT_STATUS_FALL
    });
  }
}
function* watchRequestGetListComment() {
  yield takeLatest(actionTypes.GET_LIST_COMMENT_STATUS, getListStatusComment);
}

export function* commentStatusSaga() {
  yield all([watchRequestGetListComment()]);
}
