import { AxiosResponse } from 'axios';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { ProfileApi } from 'src/api/ProfileApi';
import { setError, setSuccess, setLoading } from 'src/common/commonAction';
import {
  actionTypes,
  changeImgCoverSuccess,
  resultChangeInformationUser,
  changeImgAvatarSuccess
} from './headerAction';

function* requestDataProfile() {
  try {
    const data: AxiosResponse = yield call(ProfileApi.getMyProfile);
    if (data) {
      yield put({ type: actionTypes.GET_DATA_PROFILE_SUCCESS, data });
    } else {
      yield put({ type: actionTypes.GET_DATA_PROFILE_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_PROFILE_FALL });
  }
}

function* requestChangeImgCover(action: any) {
  const { imgCover } = action;
  try {
    const data: AxiosResponse = yield call(ProfileApi.editImgCover, imgCover, 'cover');
    if (data) {
      yield put(changeImgCoverSuccess(data));
    }
  } catch (error) {
    yield put(setError());
  }
}

function* requestChangeImgAvatar(action: any) {
  const { imgAvatar } = action;
  try {
    const data: AxiosResponse = yield call(ProfileApi.editImgCover, imgAvatar, 'avatar');
    if (data) {
      yield put(changeImgAvatarSuccess(data));
    }
  } catch (error) {
    yield put(setError());
  }
}

function* watchRequestProfile() {
  yield takeLatest(actionTypes.REQUEST_DATA_PROFILE, requestDataProfile);
}

function* watchRequestChangeImgCover() {
  yield takeLatest(actionTypes.REQUEST_CHANGE_IMG_COVER, requestChangeImgCover);
}

function* watchRequestChangeImgAvatar() {
  yield takeLatest(actionTypes.REQUEST_CHANGE_IMG_AVATAR, requestChangeImgAvatar);
}
function* requestChangeInformationUser(action: any) {
  const { formUser } = action;
  yield put(setLoading());
  try {
    const data: AxiosResponse = yield call(ProfileApi.editInforUser, formUser);
    if (data) {
      yield put(resultChangeInformationUser(data));
      yield put({
        type: actionTypes.CHANGE_INFOR_USER_SUCCESS
      });
      yield put(setSuccess());
    }
  } catch (error) {
    yield put({
      type: actionTypes.CHANGE_INFOR_USER_FAILED
    });
    yield put(setError(error.message));
  }
}

function* watchRequestChangeInformationUser() {
  yield takeLatest(actionTypes.REQUEST_CHANGE_INFOR_USER, requestChangeInformationUser);
}

export function* headerSaga() {
  yield all([
    watchRequestProfile(),
    watchRequestChangeImgCover(),
    watchRequestChangeImgAvatar(),
    watchRequestChangeInformationUser()
  ]);
}
