import { AxiosResponse } from 'axios';
import API_ROUTES from 'src/common/apiRoutes';
import { appAxios } from './appAxios';

export const PointRewardApi = {
  getListPointReward: async (page: number, limit: number) => {
    try {
      const params = {
        page,
        limit,
        receiver_type: 'User',
        sender_type: 'User'
      };

      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POINTS_TRANSACTIONS, {
        params
      });

      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      console.error(error);

      throw error;
    }
  }
};
