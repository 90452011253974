import dayjs from 'dayjs';
import { TIMEZONE } from 'src/common/constants';
import { standardizedEventTime } from 'src/common/utils';
import { IcategoryTabState } from 'src/interface/ICategoryTab';
import { actionTypes } from './categoryTabActions';

const dateCurrent =
  dayjs(new Date()).format('YYYY-MM-DD') + 'T' + dayjs(new Date()).format('HH:mm:ss') + TIMEZONE;
const initialState: IcategoryTabState = {
  listMention: [],
  basePoint: 10,
  tag_ids: [4],
  organizer: {
    '4': [],
    '2': [],
    '3': []
  },
  dateFromTo: {
    starttime: dateCurrent,
    endtime: dateCurrent
  },
  selectedOrganizersList: {
    '4': [],
    '2': [],
    '3': []
  },
  location: '',
  isEditing: false,
  editedOrganizersList: [],
  image: ''
};

export function categoryTabReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.REQUEST_ORGANIZER_MENTION: {
      return {
        ...state
      };
    }
    case actionTypes.GET_ORGANIZER_MENTION_SUCCESS: {
      return {
        ...state,
        listMention: action.mentions,
        basePoint: action.coefficientPoint
      };
    }
    case actionTypes.GET_ORGANIZER_MENTION_FALL: {
      return {
        ...state,
        listMention: []
      };
    }
    case actionTypes.PUSH_LIST_MENTION: {
      return {
        ...state
      };
    }
    case actionTypes.SAVE_LIST_MENTION: {
      const selectedOrganizersList = { ...state.selectedOrganizersList };
      selectedOrganizersList[action.tab] = action.organizersList;
      return {
        ...state,
        organizer: { ...state.organizer, ...action.organizer },
        selectedOrganizersList
      };
    }
    case actionTypes.PUSH_DATE_FROM_TO: {
      return {
        ...state
      };
    }

    case actionTypes.SAVE_DATE_FROM_TO: {
      return {
        ...state,
        dateFromTo: action.dataFromTo
      };
    }
    case actionTypes.PUSH_ACTIVE_TAB: {
      return {
        ...state
      };
    }
    case actionTypes.SAVE_ACTIVE_TAB: {
      return {
        ...state,
        tag_ids: action.activeTab
      };
    }
    case actionTypes.SAVE_LOCATION: {
      return {
        ...state,
        location: action.keyword
      };
    }
    case actionTypes.SET_EDITING_DATA: {
      return {
        ...state,
        location: action.editingData?.location,
        dateFromTo: {
          starttime: standardizedEventTime(action.editingData?.starttime),
          endtime: standardizedEventTime(action.editingData?.endtime)
        },
        editedOrganizersList: action.editingData?.organizers,
        isEditing: true,
        image: action.editingData?.image,
        id: action.editingData?.id,
        tag_ids: [action.editingData?.tags[0].id]
      };
    }
    case actionTypes.CLEAR_DATA: {
      return {
        ...state,
        location: '',
        dateFromTo: {
          starttime: dateCurrent,
          endtime: dateCurrent
        },
        selectedOrganizersList: [],
        listMention: [],
        organizer: {
          '4': [],
          '2': [],
          '3': []
        },
        isEditing: false,
        editedOrganizersList: [],
        image: '',
        tag_ids: [4]
      };
    }
    default:
      return state;
  }
}
