import {
  ApproverParams,
  ChartParams,
  DetailChartParams,
  ListChartsParams,
  TreeNode
} from 'src/interface/ITreeNode';

export const actionTypes = {
  GET_LIST_USERS_REQUEST: '@@GET_LIST_USERS_REQUEST',
  GET_LIST_USERS_SUCCESS: '@@GET_LIST_USERS_SUCCESS',

  GET_LIST_USERS_CMS_REQUEST: '@@GET_LIST_USERS_CMS_REQUEST',
  GET_LIST_USERS_CMS_SUCCESS: '@@GET_LIST_USERS_CMS_SUCCESS',

  GET_APPROVER_CHART_REQUEST: '@@GET_APPROVER_CHART_REQUEST',
  GET_APPROVER_CHART_SUCCESS: '@@GET_APPROVER_CHART_SUCCESS',

  GET_CHART_DETAIL_REQUEST: '@@GET_CHART_DETAIL_REQUEST',
  GET_CHART_DETAIL_SUCCESS: '@@GET_CHART_DETAIL_SUCCESS',

  GET_CHART_CATEGORIES_REQUEST: '@@GET_CHART_CATEGORIES_REQUEST',
  GET_CHART_CATEGORIES_SUCCESS: '@@GET_CHART_CATEGORIES_SUCCESS',

  GET_LIST_CHARTS_REQUEST: '@@GET_LIST_CHARTS_REQUEST',
  GET_LIST_CHARTS_SUCCESS: '@@GET_LIST_CHARTS_SUCCESS',

  GET_CHART_REQUEST: '@@GET_CHART_REQUEST',
  GET_CHART_SUCCESS: '@@GET_CHART_SUCCESS',

  GET_NODE_CHILDREN_REQUEST: '@@GET_NODE_CHILDREN_REQUEST',
  GET_NODE_CHILDREN_SUCCESS: '@@GET_NODE_CHILDREN_SUCCESS',

  ACTIVATE_LOADING_REQUEST: '@@ACTIVATE_LOADING_REQUEST',
  CREATE_NODE_SUCCESS: '@@CREATE_NODE_SUCCESS',
  DEACTIVATE_LOADING_REQUEST: '@@DEACTIVATE_LOADING_REQUEST'
};

export const getListUsers = () => ({
  type: actionTypes.GET_LIST_USERS_REQUEST
});

export const getListUsersCms = (params: { authen_key: string }) => ({
  type: actionTypes.GET_LIST_USERS_CMS_REQUEST,
  params
});

export const getApproverChart = (params: ApproverParams) => ({
  type: actionTypes.GET_APPROVER_CHART_REQUEST,
  params
});

export const getChartCategories = () => ({
  type: actionTypes.GET_CHART_CATEGORIES_REQUEST
});

export const getListCharts = (params: ListChartsParams) => ({
  type: actionTypes.GET_LIST_CHARTS_REQUEST,
  params
});

export const getChart = (params: ChartParams) => ({
  type: actionTypes.GET_CHART_REQUEST,
  params
});

export const getChartDetail = (params: DetailChartParams & { authen_key: string }) => ({
  type: actionTypes.GET_CHART_DETAIL_REQUEST,
  params
});

export const activateLoadingRequest = () => ({
  type: actionTypes.ACTIVATE_LOADING_REQUEST
});

export const deactivateLoadingRequest = () => ({
  type: actionTypes.DEACTIVATE_LOADING_REQUEST
});

export const updateChart = (params: TreeNode) => ({
  type: actionTypes.CREATE_NODE_SUCCESS,
  params
});

export const getListNodeChildren = (params: {
  chart_id: number;
  authen_key: string;
  node_id: number;
}) => ({
  type: actionTypes.GET_NODE_CHILDREN_REQUEST,
  params
});
