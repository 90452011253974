import { IActivityHistory, IActivityHistoryState } from 'src/interface/IActivityHistoryState';
import { actionTypes } from './activityHistoryAction';

const initialState: IActivityHistoryState = {
  data: [],
  isLoadingListActivityHistory: false,
  page: 1,
  limit: 1,
  count: 1
};

function updateData(state: IActivityHistoryState, payload: any): IActivityHistory[] {
  const { page, activity_logs } = payload;
  const data = page === 1 ? [] : [...state.data];
  data.push(...activity_logs);
  return data;
}

export function activityHistoryReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_ACTIVITY_HISTORY_REQUEST:
      return {
        ...state,
        isLoadingListActivityHistory: true
      };
    case actionTypes.GET_LIST_ACTIVITY_HISTORY_SUCCESS:
      return {
        ...state,
        data: updateData(state, action.payload),
        isLoadingListActivityHistory: false,
        count: action.payload.count,
        page: action.payload.page,
        limit: action.payload.limit
      };
    case actionTypes.GET_LIST_ACTIVITY_HISTORY_ERROR:
      return {
        ...state,
        data: [],
        isLoadingListStatus: false
      };
    case actionTypes.SET_PAGE_LIST_ACTIVITY_HISTORY:
      return {
        ...state,
        page: action.payload
      };
    default:
      return state;
  }
}
