import { IresetPasswordState } from 'src/interface/IResetPasswordState';
import { actionTypes } from './resetPasswordAction';

const initialState: IresetPasswordState = {
  status: '',
  isLoading: true
};

export const resetPasswordReducer = (state: IresetPasswordState = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_REQUEST:
      return { ...state, status: '', isLoading: true };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, status: action.status, isLoading: false };
    case actionTypes.RESET_PASSWORD_FAILURE:
    case actionTypes.CLEAR_STATUS:
      return { ...state, status: '', isLoading: false };
    default:
      return state;
  }
};
