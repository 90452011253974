import { AxiosResponse } from 'axios';
import Router from 'next/router';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { removeItemBookMark } from 'src/activities/news/newsAction';
import { listCommentDetail } from 'src/activities/NewsEventDetail/detailSelector';
import { setNewEditCommentStatusList } from 'src/activities/status/statusAction';
import { firstCommentImportStatus } from 'src/activities/status/statusSelector';
import { CreateApi } from 'src/api/CreateApi';
import { detailAPI } from 'src/api/detailAPI';
import { setError } from 'src/common/commonAction';
import { STATUS, DETAIL } from 'src/common/constants';
import { setNewEditCommentStatus } from 'src/components/CommentStatus/commentStatusAction';
import { IAppState } from 'src/interface/IAppState';
import { IcommentResonsive } from 'src/interface/ICommentStatusState';
import { IitemComment } from 'src/interface/IDetailState';
import { IdataStatus } from 'src/interface/IStatusState';
import {
  actionTypes,
  getListComment,
  getInforNews,
  getListUserLike,
  getListUserRead,
  requestListUserLike,
  getListTransaction,
  updateListComment,
  requestListUserRead,
  takeItemCommentWS
} from './DetailAction';
import {
  checkFullItemComment,
  handleScrollListComment
} from './Interactive/TabUserInteractive/Formula';

function* requestDataNews(action: any) {
  const { typeDetail, params, hasNoti, pathname } = action;
  try {
    if (hasNoti) {
      yield call(Router.push, pathname.replace('[id]', params)); // TODO: check
    } else {
      const data: AxiosResponse = yield call(detailAPI.getDataInfor, typeDetail, params);
      data ? yield put(getInforNews(data)) : yield call(Router.back);
    }
  } catch (error) {
    yield put(setError());
  }
}
function* requestInitialListComment(action: any) {
  const { data } = action;
  const idEle = 'bottom-list-comment';
  try {
    const newListComment: Array<IitemComment> = yield call(detailAPI.getListComment, data, 1);
    const fullComment: boolean = checkFullItemComment(newListComment, 1);
    yield put(getListComment(newListComment, fullComment));
    handleScrollListComment(idEle);
  } catch (error) {
    yield put(setError());
  }
}
function* requestListComment(action: any) {
  const { data, limit, oldListComment } = action;
  const idEle = limit > 1 ? 'top-list-comment' : 'bottom-list-comment';
  try {
    const newListComment: Array<IitemComment> = yield call(detailAPI.getListComment, data, limit);
    let mergeListComment: IitemComment[] = [];
    if (oldListComment.length > 0 && Number(data) === oldListComment[0].commentable_id) {
      mergeListComment = newListComment.concat(oldListComment);
    } else {
      mergeListComment = newListComment;
    }
    const fullComment: boolean = checkFullItemComment(mergeListComment, limit);
    yield put(getListComment(mergeListComment, fullComment));
    handleScrollListComment(idEle);
  } catch (error) {
    yield put(setError());
  }
}

function* readPosts(action: any) {
  const { data } = action;
  try {
    const result: AxiosResponse = yield call(detailAPI.postReadPosts, data);
    if (result) {
      yield put(requestListUserRead(data, 'read'));
    }
  } catch (error) {
    yield put(setError());
  }
}
function* reactPosts(action: any) {
  const { idPost, setLiked } = action;
  try {
    const result: AxiosResponse = yield call(detailAPI.postReactPosts, setLiked, idPost);
    if (result) {
      yield put(requestListUserLike(idPost, 'like'));
    }
  } catch (error) {
    yield put(setError());
  }
}
function* bookmarkPosts(action: any) {
  const { setBookmarkPost, idPost, shouldRemoveItem } = action;
  try {
    yield call(detailAPI.postBookmarkPosts, setBookmarkPost, idPost);
    if (shouldRemoveItem && Router.pathname.indexOf('/news') > -1) {
      yield put(removeItemBookMark(idPost));
    }
  } catch (error) {
    yield put(setError());
  }
}
function* requestListUserLikePost(action: any) {
  const { data, typeInteractive } = action;
  try {
    const listUserLike: AxiosResponse = yield call(
      detailAPI.getListInteractive,
      data,
      typeInteractive
    );
    yield put(getListUserLike(listUserLike));
  } catch (error) {
    yield put(setError());
  }
}
function* requestListUserReadPosts(action: any) {
  const { data, typeInteractive } = action;
  try {
    const listUserRead: AxiosResponse = yield call(
      detailAPI.getListInteractive,
      data,
      typeInteractive
    );
    yield put(getListUserRead(listUserRead));
  } catch (error) {
    yield put(setError());
  }
}
const setListNewComment = (list: IitemComment[], idCmt: number, result: IitemComment) => {
  return list.map((obj: IitemComment) =>
    obj?.id === Number(idCmt)
      ? {
          ...obj,
          content: result.content,
          is_edited: true,
          giphy: result.giphy ? result.giphy : null,
          images: result.images ? result.images : null
        }
      : obj
  );
};
function* updateStateWhenEditDone(
  type: string,
  result: IitemComment,
  idCmt: number,
  idPosts: number
) {
  try {
    switch (type) {
      case DETAIL: {
        const listComment: IitemComment[] = yield select(listCommentDetail);
        const newState = yield setListNewComment(listComment, idCmt, result);
        yield put(updateListComment(newState));
        break;
      }
      case STATUS.MODAL:
      case STATUS.DETAIL: {
        const dataComment: IcommentResonsive = yield select(
          (appState: IAppState) => appState.commentStatusState.dataComment[idPosts]
        );
        const listComment = dataComment.comments;
        const newState = yield setListNewComment(listComment, idCmt, result);
        yield put(setNewEditCommentStatus(idPosts.toString(), newState));
        break;
      }
      case STATUS.LIST: {
        const listStatus: IdataStatus[] = yield select(firstCommentImportStatus);

        const handleNewComments = (item: IdataStatus) => {
          if (item.newComments) {
            return {
              first_comment: setListNewComment([item.first_comment], idCmt, result)[0],
              newComments: setListNewComment(item.newComments, idCmt, result)
            };
          } else {
            return { first_comment: setListNewComment([item.first_comment], idCmt, result)[0] };
          }
        };

        const newState = yield listStatus.map((item) =>
          item.id === idPosts ? { ...item, ...handleNewComments(item) } : item
        );

        yield put(setNewEditCommentStatusList(newState));
        break;
      }
      default:
        break;
    }
  } catch (e) {
    yield put(setError());
  }
}
function* putEditCommentToServer(action: any, imageId: number[]) {
  const { idCmt, data, typeCmt, idPosts, idImg } = action;
  const paramApi = {
    content: data.content,
    giphy: data.giphy,
    image_ids: imageId[0] !== idCmt ? imageId : [idImg],
    file_ids: [],
    hashtags: data.hashtags,
    mentions: data.mentions
  };
  const result: IitemComment | any = yield call(detailAPI.putEditComment, idCmt, paramApi);
  if (result) {
    yield updateStateWhenEditDone(typeCmt, result, idCmt, idPosts);
  }
}
function* putEditComment(action: any) {
  const { data } = action;
  try {
    if (Array.isArray(data.image_ids)) {
      const dataImg: { [key: string]: any } = yield call(CreateApi.sendImg, data.image_ids);
      if (dataImg) {
        const imageId = dataImg.images && dataImg.images[0].id;
        yield putEditCommentToServer(action, [imageId]);
      }
    } else yield putEditCommentToServer(action, data.image_ids !== '' ? [data.image_ids] : []);
  } catch (error) {
    yield put(setError());
  }
}
function* likeComment(action: any) {
  try {
    const data: AxiosResponse = yield call(detailAPI.likeComment, Number(action.id), action.like);
    if (data) {
      yield put({
        type: actionTypes.LIKE_COMMENT_SUCCESS,
        id: action.id,
        like: action.like
      });
    }
  } catch (e) {
    yield put(setError());
  }
}
function* requestListTransaction(action: any) {
  const { idPost } = action;
  try {
    const result: AxiosResponse = yield call(detailAPI.getListTransaction, idPost);
    yield put(getListTransaction(result));
  } catch (error) {
    yield put(setError());
  }
}
function* getItemCommentWS(action: any) {
  const { itemComment } = action;

  try {
    if (itemComment.id !== 0 && itemComment.commentable_id !== 0) {
      yield put(takeItemCommentWS(itemComment));
    }
  } catch (error) {
    yield put(setError());
  }
}

function* watchBookmarkPosts() {
  yield takeLatest(actionTypes.BOOKMARK_POSTS, bookmarkPosts);
}
function* watchReactPosts() {
  yield takeLatest(actionTypes.REACT_POSTS, reactPosts);
}
function* watchReadPosts() {
  yield takeLatest(actionTypes.READ_POST_DONE, readPosts);
}
function* watchRequestNews() {
  yield takeLatest(actionTypes.REQUEST_INFOR_DETAIL, requestDataNews);
}
function* watchRequestInitialListComment() {
  yield takeLatest(actionTypes.REQUEST_INITIAL_LIST_COMMENT, requestInitialListComment);
}
function* watchRequestListComment() {
  yield takeLatest(actionTypes.REQUEST_LIST_COMMENT, requestListComment);
}
function* watchRequestListUserLike() {
  yield takeLatest(actionTypes.REQUEST_LIST_USER_LIKE, requestListUserLikePost);
}
function* watchRequestListUserRead() {
  yield takeLatest(actionTypes.REQUEST_LIST_USER_READ, requestListUserReadPosts);
}
function* watchPutEditComment() {
  yield takeLatest(actionTypes.PUT_EDIT_COMMENT, putEditComment);
}
function* watchLikeComment() {
  yield takeLatest(actionTypes.LIKE_COMMENT, likeComment);
}
function* watchRequestListTransaction() {
  yield takeLatest(actionTypes.REQUEST_LIST_TRANSACTION, requestListTransaction);
}
function* watchWSItemComment() {
  yield takeLatest(actionTypes.WS_ITEM_COMMENT, getItemCommentWS);
}

export function* detailSaga() {
  yield all([
    watchRequestNews(),
    watchRequestListComment(),
    watchRequestInitialListComment(),
    watchReadPosts(),
    watchReactPosts(),
    watchBookmarkPosts(),
    watchRequestListUserLike(),
    watchRequestListUserRead(),
    watchPutEditComment(),
    watchLikeComment(),
    watchRequestListTransaction(),
    watchWSItemComment()
  ]);
}
