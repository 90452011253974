import { AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const HashTagApi = {
  getListHashTag: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_TAG_NEW);
      return data.code === 200 && data.data ? data.data : false;
    } catch (error) {
      // throw error;
    }
  }
};
