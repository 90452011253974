import { AxiosResponse } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { LateOffDayAPI } from 'src/api/LateOffDayAPI';
import { actionTypes } from './lateOffDayAction';

function* requestListDataLateOffDayToday() {
  try {
    const data: AxiosResponse = yield call(LateOffDayAPI.getLateOffDay);
    yield put({
      type: actionTypes.GET_LIST_DATA_LATE_OFF_DAY_SUCCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestListDataLateOffDayToday() {
  yield takeEvery(actionTypes.GET_LIST_DATA_LATE_OFF_DAY_REQUEST, requestListDataLateOffDayToday);
}

export function* lateOffDaySaga() {
  yield all([watchRequestListDataLateOffDayToday()]);
}
