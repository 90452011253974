import { AxiosResponse } from 'axios';
import { signOut } from 'next-auth/react';
import { put, takeLatest, all, call } from 'redux-saga/effects';
import { SignInApi } from 'src/api/SignInApi';
import APP_ROUTES from 'src/common/appRoutes';
import { setLoading, setSuccess, setError } from 'src/common/commonAction';
import { KEY_SHOWED_WALKTOUR } from 'src/common/constants';
import { actionTypes } from 'src/components/signin/signinAction';

function* actionAuthen(action: any) {
  const { values } = action;
  try {
    yield put(setLoading());
    let authData = values.dataUser;
    if (!authData) {
      const { data }: AxiosResponse = yield call(SignInApi.postAccount, values);
      authData = data;
    }
    if (authData) {
      const { id, auth_token } = authData;
      localStorage.setItem('token', auth_token.toString());
      localStorage.setItem('user_id', id);
      const recent = localStorage.getItem('recent') || [];
      if (recent.length < 1) {
        localStorage && localStorage.setItem('recent', '[]');
      }
      yield put({
        type: actionTypes.LOGIN_SUCCESS,
        auth_token
      });
      yield put(setSuccess());
    } else {
      yield put({ type: actionTypes.LOGIN_FAIL });
      yield put(setError());
    }
  } catch (error) {
    yield put({ type: actionTypes.LOGIN_FAIL });
    yield put(setError());
  }
}
function* logout(action: any) {
  const { uid } = action;
  try {
    yield put(setLoading());
    const { data }: AxiosResponse = yield call(SignInApi.logout, uid);
    if (data) {
      signOut({ callbackUrl: APP_ROUTES.SIGN_IN });
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      localStorage.removeItem(KEY_SHOWED_WALKTOUR);
    }
    yield put(setSuccess());
  } catch (error) {
    yield put(setError());
  }
}

function* loginWatcher() {
  yield takeLatest(actionTypes.POST_AUTH, actionAuthen);
}

function* logoutWatcher() {
  yield takeLatest(actionTypes.LOGOUT, logout);
}

export function* signinSaga() {
  yield all([loginWatcher(), logoutWatcher()]);
}
