import dayjs from 'dayjs';
import { IchatGptState } from 'src/interface/IChatGptState';
import { actionTypes } from './chatGptAction';

const initialState: IchatGptState = {
  conversations: { data: [], isLoading: false },
  messages: { data: [], isLoading: false }
};

export function chatGptReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_CONVERSATION_REQUEST: {
      return { ...state, conversations: { ...state.conversations, isLoading: true } };
    }

    case actionTypes.GET_CONVERSATION_SUCCESS: {
      return { ...state, conversations: { data: action.payload, isLoading: false } };
    }

    case actionTypes.GET_MESSAGE_GPT_REQUEST: {
      return { ...state, messages: { ...state.messages, isLoading: true } };
    }

    case actionTypes.GET_MESSAGE_GPT_SUCCESS: {
      return { ...state, messages: { data: action.payload, isLoading: false } };
    }

    case actionTypes.UPDATE_CONVERSATION_SUCCESS: {
      const newArr = state.conversations.data;
      const index = newArr.findIndex((item) => Number(item.id) === Number(action.payload.id));
      const newDate = dayjs().toString();
      if (index !== -1) {
        newArr[index].updated_at = newDate;
        if (action.payload.title) newArr[index].title = action.payload.title;
      } else {
        newArr.push({ ...action.payload, updated_at: newDate });
      }
      newArr.sort((a, b) => dayjs(b.updated_at).unix() - dayjs(a.updated_at).unix());

      return { ...state, conversations: { data: newArr, isLoading: false } };
    }

    case actionTypes.DELETE_CONVERSATION_SUCCESS: {
      const newArr = state.conversations.data.filter((item) => item.id !== action.id);
      return { ...state, conversations: { data: newArr, isLoading: false } };
    }

    case actionTypes.RESET_MESSAGES: {
      return { ...state, messages: { data: [], isLoading: false } };
    }

    case actionTypes.UPDATE_MESSAGE_GPT_REQUEST: {
      return { ...state, messages: { data: action.messages, isLoading: false } };
    }

    case actionTypes.UPDATE_FEEDBACK_MESSAGE_GPT_REQUEST: {
      const newArr = state.messages.data;
      const indexFeedbackedMsg = newArr.findIndex((e) => e.id === action.payload.id);
      newArr[indexFeedbackedMsg].feedback = {
        rating: action.payload.rating
      };
      return { ...state, messages: { data: newArr, isLoading: false } };
    }

    default:
      return state;
  }
}
