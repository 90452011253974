import APP_ROUTES from 'src/common/appRoutes';
import { IdataRequest } from 'src/interface/IAnnouncementsState';
import { actionTypes } from './announcementsAction';

const initialState = {
  data: {
    announcements: [],
    count: 0,
    page: 0,
    limit: 0
  },
  prePathname: APP_ROUTES.HOME,
  isLoading: true,
  actionSuccess: true
};

export function announcementsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.REQUEST_DATA_LIST_ANNOUNCEMENTS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_DATA_LIST_ANNOUNCEMENTS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
        actionSuccess: true
      };
    }
    case actionTypes.GET_DATA_LIST_ANNOUNCEMENTS_NEXT_PAGE: {
      const announcements = [...state.data.announcements, ...action.data.announcements];

      let data: IdataRequest = Object.assign({}, state.data);
      data = Object.assign(data, action.data);
      data.announcements = announcements;

      return {
        ...state,
        data,
        isLoading: false,
        actionSuccess: true
      };
    }
    case actionTypes.GET_DATA_LIST_ANNOUNCEMENTS_FALL: {
      return {
        ...state,
        data: {},
        isLoading: false,
        actionSuccess: false
      };
    }
    default:
      return state;
  }
}
