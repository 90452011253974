import { AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const RewardsApi = {
  getListReward: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POINTS_REWARDS);
      return data && data.code === 200 ? data : false;
    } catch (e) {
      // throw error;
    }
  },
  getListCategoryReward: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POINTS_CATEGORY_REWARDS);
      return data && data.code === 200 ? data : false;
    } catch (e) {
      // throw error;
    }
  },
  getRewardDetail: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POINTS_REWARDS);
      return data.code === 200 && data.data ? data.data.rewards : false;
    } catch (error) {
      // throw error;
    }
  },
  postRedeem: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POINTS_REWARD_REDEEM(id));
      return data.code === 200 ? true : false;
    } catch (error) {
      // throw error;
    }
  }
};
