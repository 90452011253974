import message from 'antd/lib/message';
import axios, { AxiosResponse, AxiosRequestConfig, CancelToken } from 'axios';
// import * as gtm from 'lib/gtm';
import { eventChannel } from 'redux-saga';
import { put, takeLatest, call, all, delay, take, race, cancelled } from 'redux-saga/effects';
import { handleScrollListComment } from 'src/activities/NewsEventDetail/Interactive/TabUserInteractive/Formula';
import { CreateApi } from 'src/api/CreateApi';
import { detailAPI } from 'src/api/detailAPI';
import { StatusApi } from 'src/api/StatusApi';
import { setError } from 'src/common/commonAction';
import { STATUS } from 'src/common/constants';
import { IactionTypeState } from 'src/interface/IActionTypeState';
import { IurlBase64 } from 'src/interface/IImageCmtState';
import { IstatusResponsive, UploadFileType } from 'src/interface/IStatusState';
import {
  actionTypes,
  getStatusDetailSucess,
  requestUploadVideo,
  uploadVideoSuccess,
  uploadVideoError,
  uploadProgressChange
} from './statusAction';

function* getListStatus(action: IactionTypeState): any {
  const { page, limit, author_id, keyword, sort, isRead } = action;
  try {
    yield delay(200);
    const data: IstatusResponsive = yield call(
      StatusApi.getListStatus,
      page,
      limit,
      author_id,
      keyword,
      sort,
      isRead
    );
    if (data) {
      if (data.page > 1) {
        yield put({
          type: actionTypes.GET_LIST_STATUS_REQUEST_NEXT_PAGE,
          payload: data
        });
      } else {
        yield put({
          type: actionTypes.GET_LIST_STATUS_SUCESS,
          payload: data
        });
      }

      // if (keyword) {
      //   gtm.search('status', keyword);
      // }
    } else {
      yield put({
        type: actionTypes.GET_LIST_STATUS_ERROR
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIST_STATUS_ERROR
    });
  }
}

function* getListMention() {
  try {
    const data: AxiosResponse = yield call(StatusApi.getListMention);
    yield put({
      type: actionTypes.GET_LIST_MENTION_SUCCESS,
      payload: data
    });
    yield put({
      type: actionTypes.GET_LIST_ONLINE_REQUEST
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIST_STATUS_ERROR
    });
  }
}

function* createStatus(action: IactionTypeState, imageId: number | null, videoId: number[]): any {
  try {
    const params = {
      mentions: action.mention ? action.mention : [],
      hashtags: action.hashtag ? action.hashtag : [],
      point: action.point ? action.point : 0,
      giphy: action.giphy ? action.giphy : '',
      file_id: action.file_id ? action.file_id : null,
      image_ids: imageId,
      feeling_id: action.feeling_id ? action.feeling_id : 0,
      content: action.content ? action.content : '',
      fabric: action.fabric ? action.fabric : null,
      video_ids: videoId
    };
    const data: AxiosResponse = yield call(StatusApi.postComment, params);
    if (data.status === 406 || data.status === 500) {
      message.error(data.data.message);
      yield put({
        type: actionTypes.POST_CREATE_STATUS_ERROR,
        payload: data.data.message
      });
    } else {
      yield put({
        type: actionTypes.POST_CREATE_STATUS_SUCCESS,
        payload: data
      });
    }
    // we don't need an update state redux in here, because the socket will get new post
  } catch (err) {
    console.log(err);
  }
}

function createUploadVideoChannel(videoFiles: any[], cancelToken: CancelToken) {
  return eventChannel((emitter) => {
    const onUploadProgress = (evt: any) => {
      const percent = (evt.loaded / evt.total) * 100;
      emitter({ percent });
    };

    const video = videoFiles[0];
    const config: AxiosRequestConfig = { onUploadProgress, cancelToken };
    CreateApi.sendVideo(video, config)
      .then((data) => {
        emitter({
          success: true,
          data
        });
      })
      .catch(() => emitter({ error: true }));

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}; // require an unsubcribe function
  });
}

// upload video support cancellation
function* uploadVideo(videoFiles: any[]) {
  if (videoFiles.length < 1) {
    return;
  }

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  try {
    const progressChannel = createUploadVideoChannel(videoFiles, source.token);
    yield put(requestUploadVideo()); // show progress bar
    while (true) {
      const { percent = 0, error, success, data } = yield take(progressChannel);

      if (error) {
        yield put(uploadVideoError());
        return;
      }
      if (success) {
        yield put(uploadVideoSuccess());
        return data;
      }

      yield put(uploadProgressChange(percent));
    }
  } finally {
    if (yield cancelled()) source.cancel(); // cancel upload video
  }
}

function* createVideoStatus(action: IactionTypeState): any {
  const { video_file, edit } = action;

  const { data } = yield race({
    data: call(uploadVideo, video_file),
    cancel: take(actionTypes.CANCEL_VIDEO_UPLOAD)
  });

  if (data?.file?.id) {
    const videoId = data.file.id;
    if (edit) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      yield updateStatus(action, null, [videoId]);
    } else {
      yield createStatus(action, null, [videoId]);
    }
  }
}

function* createVideoFabric(action: IactionTypeState): any {
  const { video_file } = action;

  const { data } = yield race({
    data: call(uploadVideo, video_file),
    cancel: take(actionTypes.CANCEL_VIDEO_UPLOAD)
  });

  if (data?.file?.url) {
    const videoURL = data.file.url;
    yield put(uploadVideoSuccess(videoURL));
  }
}

function getFileTypeUpload(action: IactionTypeState): UploadFileType {
  const { image_id, video_file } = action;

  if (image_id) {
    return UploadFileType.IMAGE;
  }
  if (video_file.length > 0) {
    return UploadFileType.VIDEO;
  }

  return UploadFileType.FILE;
}

function* updateFileBeforceCreateStatus(action: IactionTypeState): any {
  try {
    const { image_id } = action;
    const fileType = getFileTypeUpload(action);
    switch (fileType) {
      case UploadFileType.IMAGE: {
        const dataImg: { [key: string]: any } = yield call(
          CreateApi.sendImg,
          image_id.map((item: any) => item.originFileObj)
        );
        if (dataImg && dataImg.images) {
          const imageId = dataImg.images.map((item: any) => item.id);
          yield createStatus(action, imageId, []);
        } else {
          message.error(dataImg?.message);
        }

        break;
      }

      default: {
        yield createStatus(action, null, []);
        break;
      }
    }
  } catch (e) {
    console.log(e);
  }
}

function* getListFeeling() {
  try {
    const data: AxiosResponse = yield call(StatusApi.getListFeeling);
    yield put({
      type: actionTypes.GET_LIST_FEELING_SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({
      type: actionTypes.GET_LIST_FEELING_ERROR
    });
  }
}

function* uploadImage(action: IactionTypeState): any {
  try {
    const formData = new FormData();
    formData.append('type', '3');
    formData.append('file', action.image);
    const data: AxiosResponse = yield call(StatusApi.uploadImage, formData);
    yield put({
      type: actionTypes.UPLOAD_IMAGE_SUCCESS,
      payload: data
    });
  } catch (err) {
    yield put({
      type: actionTypes.UPLOAD_IMAGE_ERR
    });
  }
}
function* createCommentSaga(action: IactionTypeState, imageId: number[]): any {
  const params = yield {
    mentions: action.mention,
    hashtags: action.hashtag,
    point: action.point ? action.point : 0,
    giphy: action.giphy ? action.giphy : '',
    file_ids: action.file_id ? action.file_id : [],
    image_ids: imageId,
    content: action.content ? action.content : '',
    commentable_id: action.id_content
  };
  try {
    const data: any = yield call(StatusApi.postCreateComment, params);
    if (!data?.isError) {
      if (action.typeStatus !== STATUS.DETAIL) {
        yield put({
          type: actionTypes.POST_LIST_COMMENT_STATUS_SUCCESS,
          payload: data
        });
      }
      if (action.typeStatus !== STATUS.LIST) {
        // post comment at page detail new or announcements
        yield put({
          type: actionTypes.POST_LIST_COMMENT_DETAIL_SUCESS,
          payload: data
        });
        yield delay(300);
        yield handleScrollListComment('bottom-list-comment', true);
      }
    } else {
      yield put({
        type: actionTypes.POST_LIST_COMMENT_STATUS_ERROR
      });
      message.error(data?.message);
    }
  } catch (error) {
    yield put(setError());
  }
}
function* postCreateCommentStatus(action: IactionTypeState): any {
  try {
    const { id_content, fileImg, typeStatus } = action;
    if (fileImg) {
      const dataImg: { [key: string]: any } = yield call(CreateApi.sendImg, fileImg);

      if (dataImg && dataImg.images) {
        const imageId = dataImg.images.map((item: any) => item.id);
        yield createCommentSaga(action, imageId);
      } else {
        message.error(dataImg?.message);
      }
    } else {
      yield createCommentSaga(action, []);
    }
    if (typeStatus === STATUS.DETAIL) {
      handleScrollListComment(`input-comment-${id_content}`, true);
    }
  } catch (err) {
    yield put({
      type: actionTypes.POST_LIST_COMMENT_STATUS_ERROR,
      payload: err
    });
  }
}

function* getListTag() {
  try {
    const data: AxiosResponse = yield call(StatusApi.getListTag);
    yield put({
      type: actionTypes.GET_LIST_TAG_SUCCESS,
      payload: data
    });
  } catch {
    yield put({
      type: actionTypes.GET_LIST_TAG_ERROR
    });
  }
}

function* deleteComment(action: IactionTypeState): any {
  try {
    const data: AxiosResponse = yield call(StatusApi.deleteStatus, Number(action.id));
    yield put({
      type: actionTypes.DELETE_STATUS_SUCCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* reactStatusPosts(action: any) {
  const { idPost, setLiked, likeCount } = action;
  try {
    const result: AxiosResponse = yield call(detailAPI.postReactPosts, setLiked, idPost);
    if (result) {
      yield put({
        type: actionTypes.LIKE_POST_STATUS_SUCCESS,
        idPost,
        setLiked,
        likeCount
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.LIKE_POST_STATUS_ERROR });
  }
}

function* getStatusDetailSaga(action: any) {
  try {
    const { data }: AxiosResponse = yield call(StatusApi.getStatusDetail, Number(action.id));
    yield put(getStatusDetailSucess(data));
  } catch (error) {
    yield put(setError());
  }
}

function* updateStatus(
  action: IactionTypeState,
  imageIds: number[] | null,
  id_video: number[]
): any {
  try {
    const {
      idImagesExist,
      giphy,
      videosEdits,
      feeling_id,
      mention,
      hashtag,
      content,
      id,
      fabric
    } = action;

    const params = {
      content: content,
      image_ids: imageIds ? imageIds : idImagesExist,
      file_id: null,
      giphy: giphy ? giphy[0].url : '',
      hashtags: hashtag ? hashtag : [],
      mentions: mention ? mention : [],
      feeling_id: feeling_id ? feeling_id : 0,
      video_ids: id_video.length ? id_video : videosEdits,
      fabric: fabric ? fabric : null
    };

    const data: AxiosResponse = yield call(StatusApi.editStatus, params, Number(id));
    yield put({
      type: actionTypes.EDIT_STATUS_SUCCESS,
      payload: data,
      id
    });
  } catch (e) {
    console.log(e);
  }
}

function* editStatus(action: IactionTypeState): any {
  try {
    const { image_ids } = action;
    const fileImg = image_ids
      .map((item: IurlBase64) => item.url?.originFileObj)
      .filter((p: any) => p !== undefined);
    if (fileImg.length > 0) {
      const dataImg: { [key: string]: any } = yield call(CreateApi.sendImg, fileImg);
      const images = dataImg.images.map((item: any) => item.id);
      yield updateStatus(action, images.concat(action.idImagesExist), []);
    } else {
      yield updateStatus(action, null, []);
    }
  } catch (e) {
    console.log(e);
  }
}

function* getListStatusById(action: IactionTypeState): any {
  try {
    const data: AxiosResponse = yield call(StatusApi.getStatusById, Number(action.id));
    if (data) {
      yield put({
        type: actionTypes.GET_STATUS_BY_ID_SUCCESS,
        payload: data
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* followStatus(action: any): any {
  try {
    const data: AxiosResponse = yield call(
      StatusApi.followStatus,
      Number(action.id),
      action.follow
    );
    if (data) {
      yield put({
        type: actionTypes.FOLLOW_STATUS_SUCCESS
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* getListOnline() {
  try {
    const data = yield call(StatusApi.getUsersStatus);
    yield put({
      type: actionTypes.GET_LIST_ONLINE_SUCCESS,
      payload: data.online
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIST_ONLINE_ERROR
    });
  }
}

function* watchRequestDeleteStatus() {
  yield takeLatest(actionTypes.DELETE_STATUS_REQUEST, deleteComment);
}

function* watchRequestGetListTag() {
  yield takeLatest(actionTypes.GET_LIST_TAG_REQUEST, getListTag);
}

function* watchRequestGetListMention() {
  yield takeLatest(actionTypes.GET_LIST_MENTION_REQUEST, getListMention);
}

function* watchRequestGetListOnline() {
  yield takeLatest(actionTypes.GET_LIST_ONLINE_REQUEST, getListOnline);
}

function* watchRequestGetListStatus() {
  yield takeLatest(actionTypes.GET_LIST_STATUS_REQUEST, getListStatus);
}

function* watchRequestPostCreateStatus() {
  yield takeLatest(actionTypes.POST_CREATE_STATUS_REQUEST, updateFileBeforceCreateStatus);
}

function* watchRequestGetListFeeling() {
  yield takeLatest(actionTypes.GET_LIST_FEELING_REQUEST, getListFeeling);
}

function* watchRequestPostCreateComment() {
  yield takeLatest(actionTypes.POST_LIST_COMMENT_STATUS_REQUEST, postCreateCommentStatus);
}

function* watchRequestPostImage() {
  yield takeLatest(actionTypes.UPLOAD_IMAGE_REQUEST, uploadImage);
}

function* watchRequestReactStatusPost() {
  yield takeLatest(actionTypes.LIKE_POST_STATUS_REQUEST, reactStatusPosts);
}

function* watchGetStatusDetail() {
  yield takeLatest(actionTypes.GET_STATUS_DETAIL, getStatusDetailSaga);
}

function* watchRequestUpdateStatus() {
  yield takeLatest(actionTypes.EDIT_STATUS_REQUEST, editStatus);
}

function* watchRequestGetStatusById() {
  yield takeLatest(actionTypes.GET_STATUS_BY_ID, getListStatusById);
}

function* watchFollowStatus() {
  yield takeLatest(actionTypes.FOLLOW_STATUS, followStatus);
}

function* watchCreateVideoStatus() {
  yield takeLatest(actionTypes.CREATE_VIDEO_STATUS_REQUEST, createVideoStatus);
}

function* watchCreateVideoFabric() {
  yield takeLatest(actionTypes.CREATE_VIDEO_FABRIC_REQUEST, createVideoFabric);
}

export function* statusSaga() {
  yield all([
    watchRequestGetListStatus(),
    watchRequestGetListMention(),
    watchRequestGetListOnline(),
    watchRequestPostCreateStatus(),
    watchRequestGetListFeeling(),
    watchRequestPostCreateComment(),
    watchRequestGetListTag(),
    watchRequestPostImage(),
    watchRequestDeleteStatus(),
    watchRequestReactStatusPost(),
    watchGetStatusDetail(),
    watchRequestUpdateStatus(),
    watchRequestGetStatusById(),
    watchFollowStatus(),
    watchCreateVideoStatus(),
    watchCreateVideoFabric()
  ]);
}
