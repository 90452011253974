import message from 'antd/lib/message';
import { AxiosResponse } from 'axios';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { CreateApi } from 'src/api/CreateApi';
import APP_ROUTES from 'src/common/appRoutes';
import { setLoading, setSuccess, setError } from 'src/common/commonAction';
import {
  ANNOUNCEMENT_DRAFT_CONTENT,
  ANNOUNCEMENT_DRAFT_TITLE,
  NEWS_DRAFT_CONTENT,
  NEWS_DRAFT_TITLE
} from 'src/common/constants';
import { getTabsById } from 'src/common/utils';
import { actionTypes } from './createAction';

function* requestSendDataCreateAnnouncement(action: any) {
  const { fileImg, requestData } = action;
  try {
    yield put(setLoading());
    const dataImg: { [key: string]: any } = yield call(CreateApi.sendImg, fileImg);
    if (dataImg && dataImg.images) {
      yield (requestData.image_ids = [dataImg.images[0].id]);
      const data: AxiosResponse = yield call(CreateApi.createAnnouncement, requestData);
      if (data) {
        yield put({
          type: actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT_SUCCESS,
          data
        });
        yield put(setSuccess());
        localStorage.setItem(ANNOUNCEMENT_DRAFT_TITLE, '');
        localStorage.setItem(ANNOUNCEMENT_DRAFT_CONTENT, '');
        window.location.href = APP_ROUTES.ACTIVITY_ANNOUNCEMENTS;
      } else {
        yield put({ type: actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT_FALL });
        yield put(setError());
      }
    } else {
      yield put({ type: actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT_FALL });
      message.error(dataImg?.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT_FALL });
    yield put(setError());
  }
}

function* requestSendDataCreateNew(action: any) {
  const { fileImg, requestData } = action;
  try {
    yield put(setLoading());
    const dataImg: { [key: string]: any } = yield call(CreateApi.sendImg, fileImg);
    if (dataImg.images) {
      yield (requestData.image_ids = [dataImg.images[0].id]);
      const data: AxiosResponse = yield call(CreateApi.createNew, requestData);
      if (data) {
        yield put({ type: actionTypes.SEND_DATA_CREATE_NEW_SUCCESS, data });
        yield put(setSuccess());
        localStorage.setItem(NEWS_DRAFT_TITLE, '');
        localStorage.setItem(NEWS_DRAFT_CONTENT, '');
        window.location.href = APP_ROUTES.ACTIVITY_NEWS;
      } else {
        yield put({ type: actionTypes.SEND_DATA_CREATE_NEW_FALL });
        yield put(setError());
      }
    } else {
      yield put({ type: actionTypes.SEND_DATA_CREATE_NEW_FALL });
      message.error(dataImg?.message);
    }
  } catch (error) {
    yield put({ type: actionTypes.SEND_DATA_CREATE_NEW_FALL });
    yield put(setError());
  }
}

function* requestToUpdateDateNews(action: any) {
  const { id, fileImg, requestData, image_ids } = action;
  try {
    if (id) {
      yield put(setLoading());
      let dataImg: { [key: string]: any } = {};
      if (fileImg) {
        dataImg = yield call(CreateApi.sendImg, fileImg);
      }
      if (dataImg && dataImg.images && dataImg.images[0].id) {
        yield (requestData.image_ids = [dataImg.images[0].id]);
      } else {
        yield (requestData.image_ids = image_ids);
      }
      const data: AxiosResponse = yield call(CreateApi.updateNews, id, requestData);
      if (data) {
        yield put({ type: actionTypes.SEND_DATA_UPDATE_NEWS_SUCCESS, data });
        yield put(setSuccess());
        window.location.href = `${APP_ROUTES.ACTIVITY_NEWS}/${id}`;
      } else {
        yield put({ type: actionTypes.SEND_DATA_UPDATE_NEWS_FAILED });
        yield put(setError());
      }
    } else {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
    setError();
  }
}

function* requestToUpdateDateAnnouncement(action: any) {
  const { id, fileImg, requestData, image_ids } = action;
  try {
    if (id) {
      yield put(setLoading());
      let dataImg: { [key: string]: any } = {};
      if (fileImg) {
        dataImg = yield call(CreateApi.sendImg, fileImg);
      }
      if (dataImg && dataImg.images && dataImg.images[0].id) {
        yield (requestData.image_ids = [dataImg.images[0].id]);
      } else {
        yield (requestData.image_ids = image_ids);
      }
      const data: AxiosResponse = yield call(CreateApi.updateAnnouncement, id, requestData);
      if (data) {
        yield put({ type: actionTypes.SEND_DATA_UPDATE_ANNOUNCEMENT_SUCCESS, data });
        yield put(setSuccess());
        window.location.href = `${APP_ROUTES.ACTIVITY_ANNOUNCEMENTS}/${id}`;
      } else {
        yield put({ type: actionTypes.SEND_DATA_UPDATE_ANNOUNCEMENT_FAILED });
        yield put(setError());
      }
    } else {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
    setError();
  }
}

function* previewDataCreate(action: any) {
  const { requestData, isNew } = action;
  if (isNew) {
    yield (requestData.tags = [getTabsById(requestData.tag_ids[0])]);
  } else {
    yield (requestData.tags = [
      {
        id: 1,
        name: 'Company News',
        taggings_count: 0,
        kind: 0,
        color: '#ff8800'
      }
    ]);
  }
  yield (requestData.createdAt = new Date());
  yield put({ type: actionTypes.PREVIEW_DATA_CREATE, requestData, isNew });
}

function* requestDataImgToState(action: any) {
  const { file } = action;
  yield put({ type: actionTypes.SAVE_IMG_UPLOAD, file });
}

function* watchPreviewData() {
  yield takeLatest(actionTypes.IMPORT_DATA_CREATE, previewDataCreate);
}

function* watchDataSave() {
  yield takeLatest(actionTypes.IMPORT_IMG_UPLOAD, requestDataImgToState);
}

function* watchRequestSendDataCreateAnnouncement() {
  yield takeLatest(actionTypes.SEND_DATA_CREATE_ANNOUNCEMENT, requestSendDataCreateAnnouncement);
}

function* watchRequestSendDataCreateNew() {
  yield takeLatest(actionTypes.SEND_DATA_CREATE_NEW, requestSendDataCreateNew);
}

function* watchRequestToUpdateDataNews() {
  yield takeLatest(actionTypes.SEND_DATA_UPDATE_NEWS, requestToUpdateDateNews);
}

function* watchRequestToUpdateDataAnnouncement() {
  yield takeLatest(actionTypes.SEND_DATA_UPDATE_ANNOUNCEMENT, requestToUpdateDateAnnouncement);
}

export function* createSaga() {
  yield all([
    watchRequestSendDataCreateAnnouncement(),
    watchDataSave(),
    watchRequestSendDataCreateNew(),
    watchPreviewData(),
    watchRequestToUpdateDataNews(),
    watchRequestToUpdateDataAnnouncement()
  ]);
}
