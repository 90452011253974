import { AxiosResponse } from 'axios';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { InteractiveApi } from 'src/api/InteractiveApi';
import { actionTypes } from './listUserLikeAction';

function* requestListUserLike(action: any): any {
  try {
    const { id } = action;
    const data: AxiosResponse = yield call(InteractiveApi.getListUserLike, id);
    yield put({
      type: actionTypes.GET_LIST_USER_LIKE_SUCCESS,
      payload: data,
      id: id
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestListUserLike() {
  yield takeLatest(actionTypes.GET_LIST_USER_LIKE_REQUEST, requestListUserLike);
}

export function* interactiveSaga() {
  yield all([watchRequestListUserLike()]);
}
