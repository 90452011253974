import { all } from 'redux-saga/effects';
import { activitiesSaga } from 'src/activities/activitiesSaga';
import { activityHistorySaga } from 'src/activities/activityHistory/activityHistorySaga';
import { announcementsSaga } from 'src/activities/announcements/announcementsSaga';
import { createSaga } from 'src/activities/create/createSaga';
import { searchheaderSaga } from 'src/activities/header/searchheaderSaga';
import { newsSaga } from 'src/activities/news/newsSaga';
import { detailSaga } from 'src/activities/NewsEventDetail/DetailSaga';
import { pointRewardSaga } from 'src/activities/pointReward/pointRewardSaga';
import { pollSaga } from 'src/activities/poll/pollSaga';
import { statusSaga } from 'src/activities/status/statusSaga';
import { treeChartSaga } from 'src/activities/treeChart/treeChartSaga';
import { chatGptSaga } from 'src/chatgpt/chatGptSaga';
import { interactiveSaga } from 'src/components/CardLike/listUserLikeSaga';
import { categoryTabSaga } from 'src/components/CategoryTab/categoryTabSaga';
import { chartSaga } from 'src/components/Chart/chartSaga';
import { commentStatusSaga } from 'src/components/CommentStatus/commentStatusSaga';
import { eventSaga } from 'src/components/events/eventSaga';
import { giphySaga } from 'src/components/Giphy/giphySaga';
import { headerSaga } from 'src/components/Header/headerSaga';
import formSaga from 'src/components/Heatmap/form/formSaga';
import { imageCommentSaga } from 'src/components/ImageComment/imageCommentSaga';
import { lateOffDaySaga } from 'src/components/LateOffDay/lateOffDaySaga';
import { signinSaga } from 'src/components/signin/signinSaga';
import { hashtagRecommentSaga } from 'src/components/SlideHashTag/recommentHashtagSaga';
import { dashboardSaga } from 'src/dashboard/dashboardSaga';
import { forgotPasswordSaga } from 'src/forgotPassword/forgotPasswordSaga';
import { notificationSaga } from 'src/notifications/notificationSaga';
import { portalSaga } from 'src/portal/portalSaga';
import { otherProfileSaga } from 'src/profile/OtherProfile/OtherProfileSaga';
import { chartProfileSaga } from 'src/profile/ProfileSaga';
import { resetPasswordSaga } from 'src/resetPassword/resetPasswordSaga';
import { rewardSaga } from 'src/reward/rewardSaga';

export function* appSaga() {
  yield all([
    headerSaga(),
    signinSaga(),
    eventSaga(),
    chartSaga(),
    formSaga(),
    notificationSaga(),
    chartProfileSaga(),
    forgotPasswordSaga(),
    resetPasswordSaga(),
    activitiesSaga(),
    announcementsSaga(),
    searchheaderSaga(),
    newsSaga(),
    detailSaga(),
    pollSaga(),
    statusSaga(),
    interactiveSaga(),
    commentStatusSaga(),
    hashtagRecommentSaga(),
    giphySaga(),
    imageCommentSaga(),
    categoryTabSaga(),
    createSaga(),
    rewardSaga(),
    activityHistorySaga(),
    pointRewardSaga(),
    lateOffDaySaga(),
    dashboardSaga(),
    otherProfileSaga(),
    portalSaga(),
    chatGptSaga(),
    treeChartSaga()
  ]);
}
