import { actionTypes } from 'src/portal/portalAction';

const initialState = {
  apps: {
    isLoading: false,
    data: []
  },
  recents: {
    isLoading: false,
    data: []
  }
};

export function portalReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_PORTAL_REQUEST: {
      return {
        ...state,
        apps: {
          ...state.apps,
          isLoading: true
        }
      };
    }
    case actionTypes.GET_PORTAL_SUCCESS: {
      return {
        ...state,
        apps: {
          data: action.payload,
          isLoading: false
        }
      };
    }
    case actionTypes.GET_PORTAL_RECENTS_REQUEST: {
      return {
        ...state,
        recents: {
          ...state.recents,
          isLoading: true
        }
      };
    }
    case actionTypes.GET_PORTAL_RECENTS_SUCCESS: {
      return {
        ...state,
        recents: {
          data: action.payload,
          isLoading: false
        }
      };
    }
    default:
      return state;
  }
}
