import { IdataStore } from 'src/interface/IListUSerLike';
import { actionTypes } from './listUserLikeAction';

const initialState = {
  listUserLikeData: [],
  isLoading: false
};

export function listUserLikeReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_USER_LIKE_REQUEST: {
      return { ...state, isLoading: true };
    }
    case actionTypes.GET_LIST_USER_LIKE_SUCCESS: {
      const params = {
        listLike: action.payload.users,
        id: action.id
      };
      const exist = state.listUserLikeData.find((item: IdataStore) => item.id === action.id);
      let newData: any[] = [];
      if (!exist || exist === undefined) {
        newData = [...state.listUserLikeData, params];
      } else {
        const tmp = exist as IdataStore;
        tmp.listLike = params.listLike;
        newData = state.listUserLikeData;
      }
      return { ...state, isLoading: false, listUserLikeData: newData };
    }
    default:
      return state;
  }
}
