import Message from 'antd/lib/message';
import dayjs from 'dayjs';
import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import { heatmapApi } from 'src/api/heatmapAPIService';
import { setLoading, setSuccess, setError } from 'src/common/commonAction';
import { handleRecent } from 'src/common/utils';
import { formSelector, dateSelector } from 'src/components/Heatmap/heatmapSelector';
import { FormItemState } from 'src/interface/IFormState';
import {
  actionTypes,
  removeHeatmapSuccess,
  copyHeatmapSuccess,
  postHeatmapSuccess
} from './formAction';

function* removeHeatmap(action: any) {
  try {
    const forms = yield select(formSelector);
    let newForms = forms.filter((i: FormItemState) => i.id !== action.index);
    if (forms.length > 1 && action.index === 'default') {
      newForms = newForms.map((form: FormItemState, index: number) => {
        if (index === newForms.length - 1) {
          form.id = 'default';
          form.order = 999;
          form.isVisible = true;
          return form;
        }
        return form;
      });
    }
    yield put(removeHeatmapSuccess(newForms));
  } catch (error) {
    yield put(setError());
  }
}

function setValue(form: FormItemState, data: any) {
  form.project = data.id;
  form.hour = data.hour;
  form.activity = data.activity;
  form.isPassed = true;
  form.isCopy = true;
  return form;
}

function* copyHeatmapSaga(action: any) {
  try {
    const { data } = action;
    const forms = yield select(formSelector);
    const formsFilter = forms.filter((f: FormItemState) => f.project === data.id);
    if (formsFilter.length > 0) {
      const newForms = forms.map((form: FormItemState) => {
        if (form.project === data.id) {
          return setValue(form, data);
        }
        return form;
      });
      yield put(copyHeatmapSuccess(newForms));
    } else {
      const newForms = forms.map((form: FormItemState) => {
        if (form.id === 'default') {
          return setValue(form, data);
        }
        return form;
      });
      yield put(copyHeatmapSuccess(newForms));
    }
  } catch (error) {
    yield put(setError());
  }
}

function* editHistoryHeatmapSaga(action: any) {
  try {
    const { data } = action;
    const params = {
      date: dayjs(new Date(data.date)).format(),
      hours: data.hour,
      content: data.activity,
      project_id: data.project_id,
      hm_id: data.hm_id
    };
    const { message }: { data: []; message: string } = yield call(heatmapApi.putHeatmap, params);
    if (message === 'OK') {
      yield put(postHeatmapSuccess());
      yield put(setSuccess());
    } else {
      Message.error(message);
    }
  } catch (error) {
    Message.error(error.data.message);
  }
}

function* deleteHistoryHeatmapSaga(action: any) {
  try {
    const { data } = action;
    const params = { project_id: data.project_id };
    const { message }: { data: []; message: string } = yield call(heatmapApi.deleteHeatmap, params);
    if (message === 'OK') {
      yield put(postHeatmapSuccess());
      yield put(setSuccess());
    } else {
      Message.error(message);
    }
  } catch (error) {
    Message.error(error.data.message);
  }
}

function handleFormSubmit(form: FormItemState[], date: string) {
  const items = form.map((item) => {
    const obj: {
      project_id: string | number;
      content: string;
      hours: number | null;
    } = {
      hours: item.hour,
      content: item.activity,
      project_id: item.project
    };

    return obj;
  });

  return {
    items,
    date: dayjs(date).format()
  };
}

function* postHeatmapSaga() {
  try {
    yield put(setLoading());
    const forms = yield select(formSelector);
    const date = yield select(dateSelector);
    const params = handleFormSubmit(forms, date);
    const { message }: { data: []; message: string } = yield call(heatmapApi.postHeatmap, params);
    if (message === 'OK') {
      const ids = forms.map((form: FormItemState) => form.project);
      handleRecent(ids);
      yield put(postHeatmapSuccess());
      yield put(setSuccess());
    } else {
      yield put(setError());
    }
  } catch (error) {
    yield put(setError(error.data.message));
  }
}

function* removeHeatmapWatcher() {
  yield takeLatest(actionTypes.REMOVE_HEATMAP, removeHeatmap);
}

function* copyHeatmapWatcher() {
  yield takeLatest(actionTypes.COPY_HEATMAP, copyHeatmapSaga);
}

function* postHeatmapWatcher() {
  yield takeLatest(actionTypes.POST_HEATMAP, postHeatmapSaga);
}

function* editHistoryHeatmapWatcher() {
  yield takeLatest(actionTypes.EDIT_HISTORY_HEATMAP, editHistoryHeatmapSaga);
}

function* deleteHistoryHeatmapWatcher() {
  yield takeLatest(actionTypes.DELETE_HISTORY_HEATMAP, deleteHistoryHeatmapSaga);
}

export default function* formSaga() {
  yield all([
    removeHeatmapWatcher(),
    copyHeatmapWatcher(),
    postHeatmapWatcher(),
    editHistoryHeatmapWatcher(),
    deleteHistoryHeatmapWatcher()
  ]);
}
