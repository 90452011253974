import { IotherProfileState } from 'src/interface/IOtherProfileState';
import { actionTypes } from './OtherProfileAction';

const initialState: IotherProfileState = {
  data: {
    id: 0,
    name: '',
    email: '',
    team: '',
    username: '',
    is_leader: false,
    joined_date: '',
    avatar_url: '',
    cover_url: '',
    birthday: '',
    gender: '',
    is_new_user: false,
    personal_email: '',
    need_update_info: false,
    project_count: 0,
    is_followed: false,
    follower_count: 1,
    following_count: 0,
    full_name: ''
  },
  isLoading: false,
  loadingCoverImg: false,
  loadingAvatarImg: false,
  loadingUserInformation: false
};

export function otherProfileReducer(state = initialState, action: any) {
  const { data } = action;
  switch (action.type) {
    case actionTypes.REQUEST_OTHER_PROFILE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_OTHER_PROFILE: {
      return {
        ...state,
        data: {
          ...data
        },
        isLoading: false
      };
    }
    case actionTypes.FOLLOW_PROFILE_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          is_followed: !state.data.is_followed,
          follower_count: state.data.follower_count + (state.data.is_followed ? -1 : 1)
        }
      };
    }
    default:
      return state;
  }
}
