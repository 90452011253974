import { IformForgotPassword } from 'src/interface/IForgotPasswordState';

export const actionTypes = {
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  CLEAR_PREVIOUS_EMAIL: 'CLEAR_PREVIOUS_EMAIL'
};

export const postFormForgotPassword = (formData: IformForgotPassword): any => ({
  type: actionTypes.FORGOT_PASSWORD_REQUEST,
  formData
});
