import Message from 'antd/lib/message';
import { AxiosResponse } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { PortalAPI } from 'src/api/portalAPI';
import { actionTypes, getPortals, getPortalsRecents } from './portalAction';

function* requestPortalList(action: any) {
  const { searchName } = action;
  try {
    const data: AxiosResponse = yield call(PortalAPI.getListPortal, searchName);
    yield put({
      type: actionTypes.GET_PORTAL_SUCCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* requestPortalRecentsList() {
  try {
    const data: AxiosResponse = yield call(PortalAPI.getListPortalRecents);
    yield put({
      type: actionTypes.GET_PORTAL_RECENTS_SUCCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* requestFavoritePortalApp(action: any) {
  const { data, searchName } = action;
  const params = { id: data };
  try {
    const { data } = yield call(PortalAPI.favoritePortalApp, params);
    if (data.message === 'success') {
      yield put(getPortals(searchName));
    } else {
      Message.error(data.message);
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestUnfavoritePortalApp(action: any) {
  const { data, searchName } = action;
  const params = { id: data };
  try {
    const { data } = yield call(PortalAPI.unfavoritePortalApp, params);
    if (data.message === 'success') {
      yield put(getPortals(searchName));
    } else {
      Message.error(data.message);
    }
  } catch (e) {
    console.log(e);
  }
}

function* requestTrackUsage(action: any) {
  const { data } = action;
  const params = { id: data };
  try {
    const { data } = yield call(PortalAPI.trackUsage, params);
    if (data.message === 'success') {
      yield put(getPortalsRecents());
    } else {
      Message.error(data.message);
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestPortalList() {
  yield takeEvery(actionTypes.GET_PORTAL_REQUEST, requestPortalList);
}

function* watchRequestPortalRecentsList() {
  yield takeEvery(actionTypes.GET_PORTAL_RECENTS_REQUEST, requestPortalRecentsList);
}

function* watchRequestFavoritePortalApp() {
  yield takeEvery(actionTypes.FAVORITE_APP_REQUEST, requestFavoritePortalApp);
}

function* watchRequestUnfavoritePortalApp() {
  yield takeEvery(actionTypes.UNFAVORITE_APP_REQUEST, requestUnfavoritePortalApp);
}

function* watchRequestTrackUsage() {
  yield takeEvery(actionTypes.TRACK_USAGE_REQUEST, requestTrackUsage);
}

export function* portalSaga() {
  yield all([
    watchRequestPortalList(),
    watchRequestPortalRecentsList(),
    watchRequestFavoritePortalApp(),
    watchRequestUnfavoritePortalApp(),
    watchRequestTrackUsage()
  ]);
}
