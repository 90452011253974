import { actionTypes as actionTypesEdit } from 'src/activities/status/statusAction';
import { IVideoCommentState } from 'src/interface/IVideoCommentState';
import { SELECT_VIDEO_COMMENT, DELETE_VIDEO_COMMENT } from './videoCommentAction';

const initState: IVideoCommentState = {
  file: [],
  videoURL: '',
  videoId: -999,
  videoId_exist: 0
};

export function videoCommentReducer(state = initState, action: any) {
  switch (action.type) {
    case SELECT_VIDEO_COMMENT: {
      return {
        ...state,
        file: [action.file],
        videoURL: URL.createObjectURL(action.file),
        videoId: action.id
      };
    }
    case DELETE_VIDEO_COMMENT: {
      return {
        ...state,
        file: [],
        videoURL: '',
        videoId: -999,
        videoId_exist: 0
      };
    }
    case actionTypesEdit.GET_STATUS_BY_ID_SUCCESS: {
      return {
        ...state,
        videoURL: action.payload.videos,
        videoId_exist: action.payload.videos && action.payload.videos[0].id
      };
    }
    case actionTypesEdit.CLOSE_MODAL: {
      return {
        ...state,
        videoURL: '',
        file: []
      };
    }
  }

  return state;
}
