import { AxiosError, AxiosResponse } from 'axios';
import API_ROUTES from 'src/common/apiRoutes';
import { Iconversation, RATING } from 'src/interface/IChatGptState';
import { appAxiosAsk } from './appAxios';

export const GptAPI = {
  createConversation: async (model: string) => {
    try {
      const { data }: AxiosResponse = await appAxiosAsk.post(API_ROUTES.CONVERSATION, { model });
      return data as Iconversation;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListConversation: async () => {
    try {
      const { data }: AxiosResponse = await appAxiosAsk.get(API_ROUTES.CONVERSATION);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  deleteConversation: async (id: string) => {
    try {
      const response: AxiosResponse = await appAxiosAsk.delete(API_ROUTES.CONVERSATION_DETAIL(id));
      return response;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  editConversation: async (params: { id: string; title: string }) => {
    try {
      const response: AxiosResponse = await appAxiosAsk.patch(
        API_ROUTES.CONVERSATION_DETAIL(params.id),
        { title: params.title }
      );
      return response;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListMessage: async (conversation_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxiosAsk.post(API_ROUTES.GPT_MESAGE, {
        conversation_id: conversation_id
      });
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  handleReactionMessageAPI: async (
    msg_id: string,
    rating: RATING,
    feedback: string,
    tags?: string[]
  ) => {
    try {
      const { data }: AxiosResponse =
        rating === RATING.NEGATIVE
          ? await appAxiosAsk.post(API_ROUTES.GPT_FEEDBACK(msg_id), {
              rating: RATING.NEGATIVE,
              feedback: feedback,
              tags: tags
            })
          : await appAxiosAsk.post(API_ROUTES.GPT_FEEDBACK(msg_id), {
              rating: RATING.POSITIVE
            });
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },

  checkLimitConversations: async () => {
    try {
      const { data }: AxiosResponse = await appAxiosAsk.get(API_ROUTES.LIMIT_CONVERSATIONS);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getModelsConversations: async () => {
    try {
      const { data }: AxiosResponse = await appAxiosAsk.get(API_ROUTES.GPT_MODELS);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  }
};
