import { IeventState } from 'src/interface/IEventState';

export const actionTypes = {
  GET_LIST_DATA_EVENT_REQUEST: '@@GET_LIST_DATA_EVENT_REQUEST',
  GET_LIST_DATA_EVENT_SUCCESS: '@@GET_LIST_DATA_EVENT_SUCCESS',
  GET_LIST_DATA_EVENT_ERROR: '@@GET_LIST_DATA_EVENT_ERROR'
};

export const getListDataEvent = (data: Array<IeventState>) => ({
  type: actionTypes.GET_LIST_DATA_EVENT_REQUEST,
  data
});
