import { all, takeLatest, put } from 'redux-saga/effects';
import { actionTypes } from './giphyAction';

function* requestDataGiphyToState(action: any) {
  const { urlGif, id, isComment } = action;
  if (isComment) {
    yield put({ type: actionTypes.SAVE_GIFPHY_COMMENT, urlGif, id });
  } else {
    yield put({ type: actionTypes.SAVE_GIPHY, urlGif, id });
  }
}
function* watchClickGiphy() {
  yield takeLatest(actionTypes.CLICK_GIPHY, requestDataGiphyToState);
}
export function* giphySaga() {
  yield all([watchClickGiphy()]);
}
