import { AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const NotificationApi = {
  getListNotification: async (params: { page: number; limit: number }) => {
    try {
      const { page, limit } = params;
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_NOTIFICATIONS, {
        params: { page: page, limit: limit }
      });
      return data.code === 200 && data ? data : false;
    } catch (error) {
      // throw error;
    }
  },

  readNotification: async (id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_NOTIFICATION_READ(id));
      return data.code === 200 && data ? data : false;
    } catch (error) {
      // throw error;
    }
  },

  addDevice: async (params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_USER_DEVICES, params);
      return data.code === 200 && data ? data : false;
    } catch (error) {
      // throw error;
    }
  },
  countNotiTab: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_NEW_POST);
      return data.code === 200 && data && data.data && data.data.count ? data.data.count : false;
    } catch (error) {
      // throw error;
    }
  },

  readAllNotification: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_NOTIFICATIONS_READ_ALL);
      return data;
    } catch (error) {
      // throw error;
    }
  }
};
