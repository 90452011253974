import { put, takeLatest, call, all } from 'redux-saga/effects';

import { PointRewardApi } from 'src/api/PointRewardApi';
import { IactionTypeState } from 'src/interface/IActionTypeState';
import { IPointRewardResponse } from 'src/interface/IPointRewardState';
import { actionTypes } from './pointRewardAction';

function* getListPointReward(action: IactionTypeState): any {
  const { page, limit } = action;
  try {
    const data: IPointRewardResponse = yield call(
      PointRewardApi.getListPointReward,
      Number(page),
      Number(limit)
    );
    if (data) {
      yield put({
        type: actionTypes.GET_LIST_POINT_REWARD_SUCESS,
        payload: data
      });
    } else {
      throw new Error();
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_LIST_POINT_REWARD_ERROR
    });
  }
}

function* watchRequestGetListPointReward() {
  yield takeLatest(actionTypes.GET_LIST_POINT_REWARD_REQUEST, getListPointReward);
}

export function* pointRewardSaga() {
  yield all([watchRequestGetListPointReward()]);
}
