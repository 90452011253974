// import * as gtm from 'lib/gtm';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { AnnouncementsApi } from 'src/api/AnnouncementsApi';
import { IdataRequest } from 'src/interface/IAnnouncementsState';
import { actionTypes } from './announcementsAction';

function* requestDataListAnnouncements(action: any) {
  const { requestData } = action;
  try {
    const data: IdataRequest = yield call(AnnouncementsApi.getListAnnouncements, requestData);
    if (data) {
      if (data.page > 1) {
        yield put({ type: actionTypes.GET_DATA_LIST_ANNOUNCEMENTS_NEXT_PAGE, data });
      } else {
        yield put({ type: actionTypes.GET_DATA_LIST_ANNOUNCEMENTS_SUCCESS, data });
      }
    } else {
      yield put({ type: actionTypes.GET_DATA_LIST_ANNOUNCEMENTS_FALL });
    }

    // if (requestData.keyword) {
    //   gtm.search('announcement', requestData.keyword);
    // }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_LIST_ANNOUNCEMENTS_FALL });
  }
}
function* watchRequestProfile() {
  yield takeLatest(actionTypes.REQUEST_DATA_LIST_ANNOUNCEMENTS, requestDataListAnnouncements);
}

export function* announcementsSaga() {
  yield all([watchRequestProfile()]);
}
