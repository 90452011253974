export const actionTypes = {
  REQUEST_OTHER_PROFILE: 'REQUEST_OTHER_PROFILE',
  GET_OTHER_PROFILE: 'GET_OTHER_PROFILE',
  FOLLOW_PROFILE: 'FOLLOW_PROFILE',
  FOLLOW_PROFILE_SUCCESS: 'FOLLOW_PROFILE_SUCCESS',
  FOLLOW_PROFILE_ERROR: 'FOLLOW_PROFILE_ERROR'
};

export const requestOtherProfile = (id: string) => ({
  type: actionTypes.REQUEST_OTHER_PROFILE,
  id
});
export const getOtherProfile = (data: any) => ({
  type: actionTypes.GET_OTHER_PROFILE,
  data
});

export const followProfile = (id: number, follow: boolean) => ({
  type: actionTypes.FOLLOW_PROFILE,
  id,
  follow
});
