import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';
import { setError } from 'src/common/commonAction';

export const CreateApi = {
  sendImg: async (fileImg: any) => {
    try {
      const formData = new FormData();
      formData.append('type', '0');
      fileImg && fileImg.map((item: any) => formData.append('file', item));
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_FILES, formData);
      return data && data.code === 200 ? data.data : false;
    } catch (err) {
      return { isError: true, message: err?.response?.data?.message };
    }
  },
  sendVideo: async (file: any, config: AxiosRequestConfig) => {
    try {
      const formData = new FormData();
      formData.append('type', '3');
      formData.append('file', file);
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_FILES, formData, config);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // throw error;
    }
  },
  createAnnouncement: async (dataSend: { [key: string]: any }) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_ANNOUNCEMENTS, dataSend);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // throw error;
    }
  },
  createNew: async (dataSend: { [key: string]: any }) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_NEWS, dataSend);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // throw error;
    }
  },
  updateNews: async (id: number, dataSend: { [key: string]: any }) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(API_ROUTES.POSTS_NEW_DETAIL(id), dataSend);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      setError();
    }
  },
  updateAnnouncement: async (id: number, dataSend: { [key: string]: any }) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(
        API_ROUTES.POSTS_ANNOUNCEMENT_DETAIL(id),
        dataSend
      );
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      setError();
    }
  },
  mentions: async () => {
    try {
      const params = {
        limit: 1000
      };
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_USER_MENTIONS, {
        params
      });
      return data && data.data && data.data.mentions ? data.data.mentions : false;
    } catch (error) {
      // throw error;
    }
  },
  eventCoefficientPoints: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.CONFIGS);
      if (data && data.app_configs) {
        let result = {};
        data.app_configs.forEach((value: any) => {
          if (value && value.key === 'event_coefficient_points') {
            result = value.value;
          }
        });
        return +result;
      }
      return false;
    } catch (error) {
      // throw error;
    }
  },
  deleteFile: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.delete(API_ROUTES.POSTS_FILE_DETAIL(id));
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // throw error;
    }
  }
};
