import { AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const StatusApi = {
  getListStatus: async (
    page: number,
    limit: number,
    author_id?: number,
    keyword?: string,
    sort?: string,
    isRead?: boolean
  ) => {
    try {
      const params = {
        keyword,
        order_by: sort,
        is_read: isRead,
        author_id,
        page,
        limit
      };

      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_STATUSES_GET, { params });

      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      // throw error;
    }
  },
  getListMention: async () => {
    try {
      const params = {
        limit: 1000
      };
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_USER_MENTIONS, {
        params
      });
      return data && data.code === 200 ? data.data : false;
    } catch (err) {
      // throw error;
    }
  },
  getUsersStatus: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.USERS_STATUS);
      if (data.errors) {
        throw new Error(data.errors);
      }
      return data;
    } catch (err) {
      // throw error;
    }
  },
  postComment: async (params: Record<string, any>) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_STATUSES_POST, params);
      return data && data.code === 200 ? data.data : data;
    } catch (err) {
      return err.response;
    }
  },
  getListFeeling: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_FEELINGS);
      return data && data.code === 200 ? data.data : false;
    } catch (err) {
      //throw err;
    }
  },
  postCreateComment: async (params: Record<string, any>) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_COMMENTS, params);
      return data && data.code === 200 ? data.data : false;
    } catch (err) {
      //throw err;
      return { isError: true, message: err?.response?.data?.message };
    }
  },
  getListTag: async () => {
    try {
      const params = {
        limit: 1000
      };
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_TAG_STATUS, {
        params
      });
      return data && data.code === 200 ? data.data : false;
    } catch (err) {
      //throw err;
    }
  },
  uploadImage: async (image: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_FILES, image);
      return data && data.code === 200 ? data.data : false;
    } catch (err) {
      return { isError: true, message: err?.response?.data?.message };
    }
  },
  updateComment: async (id: number, params: Record<string, any>) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(
        API_ROUTES.POSTS_COMMENT_DETAIL(id),
        params
      );
      return data && data.code === 200 ? data.data : false;
    } catch (err) {
      //throw err;
    }
  },
  getListComment: async (id: number, page: number) => {
    try {
      const params = {
        commentable_id: id,
        commentable_type: 'Post',
        page,
        limit: 10,
        sort_by: 'created_at',
        order_by: 'desc'
      };
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_COMMENTS, { params });
      if (data && data.data) {
        const result = {
          comments: data.data.comments.reverse(),
          count: data.data.count,
          limit: data.data.limit,
          page: data.data.page
        };
        return result;
      }
      return false;
    } catch (err) {
      //throw err;
    }
  },
  deleteStatus: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.delete(API_ROUTES.POSTS_COMMENT_DETAIL(id));
      return data && data.code === 200 ? data.data : false;
    } catch (err) {
      //throw err;
    }
  },
  createComment: async (params: Record<string, any>) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_COMMENTS, params);
      return data && data.code === 200 ? data.data : false;
    } catch (e) {
      //throw err;
    }
  },
  likeComment: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_LIKE_POST(id));
      return data && data.code === 200 ? data.data : false;
    } catch (e) {
      //throw err;
    }
  },
  getStatusDetail: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_STATUS_DETAIL(id));
      return data && data.code === 200 ? data : false;
    } catch (err) {
      //throw err;
    }
  },
  getStatusById: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_STATUS_DETAIL(id));
      return data && data.code === 200 ? data.data : false;
    } catch (e) {
      //throw err;
    }
  },
  editStatus: async (param: any, id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(API_ROUTES.POSTS_STATUS_DETAIL(id), param);
      return data && data.code === 200 ? data.data : false;
    } catch (e) {
      //throw err;
    }
  },
  followStatus: async (id: number, follow: boolean) => {
    try {
      const { data }: AxiosResponse = follow
        ? await appAxios.post(API_ROUTES.POSTS_FOLLOW_POST(id))
        : await appAxios.delete(API_ROUTES.POSTS_FOLLOW_POST(id));
      return data && data.code === 200 ? data.data : false;
    } catch (e) {
      //throw err;
    }
  }
};
