import React, { useEffect } from 'react';
import { ConfigProvider, message } from 'antd';
import * as gtag from 'lib/gtag';
import { SessionProvider } from 'next-auth/react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { ThemeProvider } from 'styled-components';
import appStore from 'src/app/appStore';
import { getPageTitle } from 'src/common/utils';
// import GoogleTagManager from 'src/components/GoogleTagManager';
import { MyAppProps } from 'src/interface/IApp';
import { registerServiceWorker } from 'src/register-sw';
import { theme } from 'src/styled/Theme';
import { init } from 'src/utils/sentry';
import 'antd/dist/antd.css';
import 'cus-draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-emoji-plugin/lib/plugin.css';
import 'draft-js-focus-plugin/lib/plugin.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'src/activities/images/preview.css';
import 'src/activities/poll/poll.css';
import 'src/components/Fabric/font.css';
import 'src/components/StickerPicker/styles.css';
import 'src/index.css';

init();
message.config({
  duration: 2,
  maxCount: 1
});
const MyApp = ({ Component, pageProps: { session, ...pageProps } }: MyAppProps) => {
  const getLayout = Component.getLayout || ((page) => page);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    registerServiceWorker();
  });

  return (
    <SessionProvider session={session}>
      {/* <GoogleTagManager> */}
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          <NextSeo title={getPageTitle()} description="OFFWORK - EXECUTIONLAB" />
          {getLayout(<Component {...pageProps} />)}
        </ThemeProvider>
      </ConfigProvider>
      {/* </GoogleTagManager> */}
    </SessionProvider>
  );
};

export default appStore.withRedux(MyApp);
