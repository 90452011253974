import { IprofileState } from 'src/interface/IProfileState';
import { actionTypes } from './headerAction';

const initialState: IprofileState = {
  data: {
    id: 0,
    name: '',
    email: '',
    team: '',
    username: '',
    is_leader: false,
    joined_date: '',
    avatar_url: '',
    cover_url: '',
    remaining_days: 0,
    phone: '',
    birthday: '',
    gender: '',
    is_new_user: false,
    device_token: '',
    id_card: '',
    personal_email: '',
    permanent_address: '',
    need_update_info: false,
    project_count: 0,
    current_address: '',
    vietcombank_account: '',
    received_points: 0,
    given_points: 0,
    used_points: 0,
    remaining_points: 0,
    notification_count: 0,
    is_admin: false,
    follower_count: 0,
    following_count: 0,
    full_name: ''
  },
  isLoading: true,
  loadingCoverImg: false,
  loadingAvatarImg: false,
  loadingUserInformation: false,
  cable: undefined,
  isUpdateProfileSuccess: false
};

export function headerReducer(state = initialState, action: any) {
  const { data } = action;
  switch (action.type) {
    case actionTypes.REQUEST_DATA_PROFILE: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_DATA_PROFILE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    }
    case actionTypes.GET_DATA_PROFILE_FALL: {
      return {
        ...state,
        data: initialState.data,
        isLoading: false
      };
    }
    case actionTypes.REQUEST_CHANGE_IMG_AVATAR: {
      return {
        ...state,
        loadingAvatarImg: true
      };
    }
    case actionTypes.REQUEST_CHANGE_IMG_COVER: {
      return {
        ...state,
        loadingCoverImg: true
      };
    }
    case actionTypes.GET_RESULT_IMG_COVER: {
      return {
        ...state,
        loadingCoverImg: false,
        data: {
          ...state.data,
          cover_url: data.cover_url
        }
      };
    }
    case actionTypes.GET_RESULT_IMG_AVATAR: {
      return {
        ...state,
        loadingAvatarImg: false,
        data: {
          ...state.data,
          avatar_url: data.avatar_url
        }
      };
    }
    case actionTypes.GET_RESULT_INFOR_USER: {
      return {
        ...state,
        data: data
      };
    }
    case actionTypes.CREATE_CABLE: {
      return {
        ...state,
        cable: data
      };
    }
    case actionTypes.REMOVE_CABLE: {
      return {
        ...state,
        cable: undefined
      };
    }
    case actionTypes.REQUEST_CHANGE_INFOR_USER: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.CHANGE_INFOR_USER_SUCCESS: {
      return {
        ...state,
        isUpdateProfileSuccess: true,
        isLoading: false
      };
    }
    case actionTypes.CHANGE_INFOR_USER_FAILED: {
      return {
        ...state,
        isUpdateProfileSuccess: false,
        isLoading: false
      };
    }
    default:
      return state;
  }
}
