export const actionTypes = {
  GET_PORTAL_REQUEST: '@@GET_PORTAL_REQUEST',
  GET_PORTAL_SUCCESS: '@@GET_PORTAL_SUCCESS',
  GET_PORTAL_ERROR: '@@GET_PORTAL_ERROR',

  GET_PORTAL_RECENTS_REQUEST: '@@GET_PORTAL_RECENTS_REQUEST',
  GET_PORTAL_RECENTS_SUCCESS: '@@GET_PORTAL_RECENTS_SUCCESS',
  GET_PORTAL_RECENTS_ERROR: '@@GET_PORTAL_RECENTS_ERROR',

  FAVORITE_APP_REQUEST: '@@FAVORITE_PORTAL_REQUEST',
  UNFAVORITE_APP_REQUEST: '@@UNFAVORITE_PORTAL_REQUEST',
  TRACK_USAGE_REQUEST: '@@TRACK_USAGE_REQUEST'
};

export const getPortals = (searchName: string) => ({
  type: actionTypes.GET_PORTAL_REQUEST,
  searchName
});

export const getPortalsRecents = () => ({
  type: actionTypes.GET_PORTAL_RECENTS_REQUEST
});

export const favoriteApp = (data: any, searchName: string) => {
  return {
    type: actionTypes.FAVORITE_APP_REQUEST,
    data,
    searchName
  };
};

export const unfavoriteApp = (data: any, searchName: string) => ({
  type: actionTypes.UNFAVORITE_APP_REQUEST,
  data,
  searchName
});

export const trackUsage = (data: any) => ({
  type: actionTypes.TRACK_USAGE_REQUEST,
  data
});
