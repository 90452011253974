import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { NotificationApi } from 'src/api/notificationsApi';
import { setError } from 'src/common/commonAction';
import { notificatonSelector } from 'src/notifications/notificationSelector';
import {
  actionTypes,
  getNotificationList,
  getNotificationListPending,
  getNotificationListSuccess,
  makeReadAllNotificationSuccess,
  makeReadNotificationSucess
} from './notificationAction';

function* setTokenFirebaseSaga(action) {
  try {
    const { params } = action;
    yield call(NotificationApi.addDevice, params);
  } catch (error) {
    console.log(error);
  }
}

function* getNotificationSaga(action) {
  const { params, resetNoti } = action;
  // reset params when resetNoti = true
  const resetParams = resetNoti ? { page: 1, limit: params.limit } : params;
  try {
    const { notifications } = yield select(notificatonSelector);
    const { data }: AxiosResponse = yield call(NotificationApi.getListNotification, resetParams);
    yield put(getNotificationListPending());
    const hasLoadmore = resetParams.page * resetParams.limit < data.count;
    // set next params loadmore
    const newParams = hasLoadmore
      ? {
          page: resetParams.page + 1,
          limit: resetParams.limit
        }
      : resetParams;
    // set notificaiton or concat notifications with resetNoti
    const newData = resetNoti ? data.notifications : notifications.concat(data.notifications);
    yield put(
      getNotificationListSuccess(newData, newParams, hasLoadmore, data.unread_num, data.count)
    );
  } catch (error) {
    yield put(setError());
  }
}

function* readNotificationSaga(action) {
  try {
    const { id } = action;
    const { params } = yield select(notificatonSelector);
    const { data }: AxiosResponse = yield call(NotificationApi.readNotification, id);
    yield put(makeReadNotificationSucess(id, data.unread_num));
    // call list after read notification
    yield put(getNotificationList(params, true));
  } catch (error) {
    yield put(setError());
  }
}

function* readAllNotificationSaga() {
  try {
    const { data } = yield call(NotificationApi.readAllNotification);
    if (data) yield put(makeReadAllNotificationSuccess());
  } catch (error) {
    yield put(setError());
  }
}

function* watchReadNotification() {
  yield takeLatest(actionTypes.READ_NOTIFICATION, readNotificationSaga);
}

function* watchGetNotification() {
  yield takeLatest(actionTypes.GET_NOTIFICATION, getNotificationSaga);
}

function* watchSetTokenFirebase() {
  yield takeLatest(actionTypes.SET_TOKEN_FIREBASE, setTokenFirebaseSaga);
}

function* watchReadAllNotification() {
  yield takeLatest(actionTypes.READ_ALL_NOTIFICATION, readAllNotificationSaga);
}

export function* notificationSaga() {
  yield all([
    watchGetNotification(),
    watchReadNotification(),
    watchSetTokenFirebase(),
    watchReadAllNotification()
  ]);
}
