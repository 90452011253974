import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';
import { IformSignin } from 'src/interface/ILoginState';

export const SignInApi = {
  postAccount: async (fromData: IformSignin) => {
    try {
      const data = await appAxios.post(API_ROUTES.SIGN_IN, fromData);
      return data ? data : false;
    } catch (error) {
      // throw error;
    }
  },
  logout: async (uid: string) => {
    try {
      const data = await appAxios.get(API_ROUTES.LOG_OUT, { params: { uid: uid } });
      return data ? data : false;
    } catch (error) {
      // throw error;
    }
  }
};
