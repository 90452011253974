import { Ifeedback, Imessage } from 'src/interface/IChatGptState';

export const actionTypes = {
  GET_CONVERSATION_REQUEST: '@@GET_CONVERSATION_REQUEST',
  GET_CONVERSATION_SUCCESS: '@@GET_CONVERSATION_SUCCESS',
  GET_CONVERSATION_ERROR: '@@GET_CONVERSATION_ERROR',

  DELETE_CONVERSATION_REQUEST: '@@DELETE_CONVERSATION_REQUEST',
  DELETE_CONVERSATION_SUCCESS: '@@DELETE_CONVERSATION_SUCCESS',
  DELETE_CONVERSATION_ERROR: '@@DELETE_CONVERSATION_ERROR',

  EDIT_CONVERSATION_REQUEST: '@@EDIT_CONVERSATION_REQUEST',
  EDIT_CONVERSATION_SUCCESS: '@@EDIT_CONVERSATION_SUCCESS',
  EDIT_CONVERSATION_ERROR: '@@EDIT_CONVERSATION_ERROR',

  UPDATE_CONVERSATION_REQUEST: '@@UPDATE_CONVERSATION_REQUEST',
  UPDATE_CONVERSATION_SUCCESS: '@@UPDATE_CONVERSATION_SUCCESS',

  GET_MESSAGE_GPT_REQUEST: '@@GET_MESSAGE_GPT_REQUEST',
  GET_MESSAGE_GPT_SUCCESS: '@@GET_MESSAGE_GPT_SUCCESS',
  GET_MESSAGE_GPT_ERROR: '@@GET_MESSAGE_GPT_ERROR',
  HANDLE_REACTION: '@@HANDLE_REACTION',
  UPDATE_FEEDBACK_MESSAGE_GPT_REQUEST: '@@UPDATE_FEEDBACK_MESSAGE_GPT_REQUEST',
  UPDATE_MESSAGE_GPT_REQUEST: '@@UPDATE_MESSAGE_GPT_REQUEST',

  RESET_MESSAGES: '@@RESET_MESSAGES'
};

export const getConversation = () => ({
  type: actionTypes.GET_CONVERSATION_REQUEST
});

export const deleteConversation = (id: string, resolve: () => void) => ({
  type: actionTypes.DELETE_CONVERSATION_REQUEST,
  payload: { id, resolve }
});

export const editConversation = (params: { id: string; title: string }) => ({
  type: actionTypes.EDIT_CONVERSATION_REQUEST,
  params
});

export const updateStateConversation = (params: {
  id: string;
  title?: string;
  model?: string;
}) => ({
  type: actionTypes.UPDATE_CONVERSATION_REQUEST,
  params
});

export const getMessageGPT = (id: string) => ({
  type: actionTypes.GET_MESSAGE_GPT_REQUEST,
  id
});

export const resetChatMessages = () => ({
  type: actionTypes.RESET_MESSAGES
});

export const handleReactionMessageGPT = (params: Ifeedback) => ({
  type: actionTypes.HANDLE_REACTION,
  params
});

export const updateMessageGPT = (messages: Imessage[]) => ({
  type: actionTypes.UPDATE_MESSAGE_GPT_REQUEST,
  messages
});
