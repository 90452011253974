import { actionTypes as actionTypesDetail } from 'src/activities/NewsEventDetail/DetailAction';
import { imageTypes } from 'src/common/constants';
import { actionTypes as actionTypesGiphy } from 'src/components/Giphy/giphyAction';
import { actionTypes as actionTypesImage } from 'src/components/ImageComment/imageCommentAction';
import {
  IdataStatus,
  IUploadVideoStatus,
  UploadFileType,
  IstatusState,
  IdataMention
} from 'src/interface/IStatusState';
import { actionTypes } from './statusAction';

const uploadVideoStatus: IUploadVideoStatus = {
  uploading: false,
  uploadError: false,
  success: false,
  fileType: undefined,
  progress: 0,
  fileURL: undefined
};

const initialState: IstatusState = {
  data: [],
  isLoading: false,
  isLoadingListStatus: false,
  page: 1,
  limit: 1,
  count: 1,
  mention: [],
  feeling: [],
  tag: [],
  messageErrorStatus: '',
  loading: false,
  img: {
    id: 1,
    user_id: 0,
    key: '',
    kind: 1,
    url: ',',
    created_at: '',
    updated_at: ''
  },
  listComment: [],
  detail: {
    newComments: [],
    author_id: 0,
    id: 0,
    feeling_id: 0,
    author_type: '',
    kind: 0,
    content: '',
    like_count: 0,
    comment_count: 0,
    review: {
      is_read: false,
      is_liked: false,
      is_bookmarked: false,
      is_clicked: false,
      is_followed: false
    },
    user: {
      avatar_url: '',
      username: '',
      name: '',
      team: ''
    },
    images: [],
    file: '',
    giphy: '',
    created_at: '',
    updated_at: ''
  },
  statusById: {
    author_id: 0,
    author_type: '',
    comment_count: 0,
    content: '',
    created_at: '',
    feeling: null,
    feeling_id: 0,
    file: '',
    first_comment: {},
    giphy: '',
    id: 0,
    images: [],
    kind: 0,
    like_count: 0,
    review: {},
    total_point: 0,
    updated_at: '',
    user: {
      avatar_url: '',
      username: '',
      name: '',
      team: ''
    }
  },
  getStatusIsLoading: true,
  loadingBtn: false,
  openModal: false,
  idModal: 0,
  idPreviewModal: 0,
  imageType: '',
  uploadVideoStatus,
  saveMyStatus: {},
  isCreateFail: false,
  users: []
};

export function statusReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_STATUS_REQUEST: {
      return {
        ...state,
        isLoadingListStatus: true
      };
    }
    case actionTypes.GET_LIST_STATUS_SUCESS: {
      return {
        ...state,
        data: action.payload.statuses || [],
        isLoadingListStatus: false,
        count: action.payload.count,
        page: action.payload.page,
        limit: action.payload.limit
      };
    }
    case actionTypes.GET_LIST_STATUS_REQUEST_NEXT_PAGE: {
      return {
        ...state,
        data: [...state.data, ...action.payload.statuses],
        isLoadingListStatus: false,
        count: action.payload.count,
        page: action.payload.page,
        limit: action.payload.limit
      };
    }
    case actionTypes.GET_NEW_STATUS_WS: {
      const stateData: { [key: string]: any } = state.data[0] && state.data[0];
      const sortData = action.payload.sort(function (a: any, b: any) {
        return b.id - a.id;
      });
      const newData: any[] = stateData
        ? stateData.id !== action.payload.id
          ? [...sortData, ...state.data]
          : state.data
        : [];
      return {
        ...state,
        data: stateData ? newData : sortData
      };
    }
    case actionTypes.GET_LIST_STATUS_ERROR: {
      return { ...state, data: [], isLoadingListStatus: false };
    }
    case actionTypes.GET_LIST_MENTION_REQUEST: {
      return { ...state, isLoading: true };
    }
    case actionTypes.GET_LIST_MENTION_SUCCESS: {
      return {
        ...state,
        mention: action.payload.mentions.map((display: any) => ({
          id: display.id,
          name: display.name || display.username,
          avatar_url: display.avatar_url
        })),
        isLoading: false
      };
    }
    case actionTypes.CLEAR_LIST_MENTION: {
      return {
        ...state,
        mention: initialState.mention
      };
    }
    case actionTypes.GET_LIST_ONLINE_REQUEST: {
      return { ...state };
    }
    case actionTypes.GET_LIST_ONLINE_SUCCESS: {
      const mention: IdataMention[] = [];

      state.mention.forEach((m: IdataMention) => {
        m.is_online = action.payload.indexOf(m.id) !== -1;
        mention.push(m);
      });

      return {
        ...state,
        mention
      };
    }
    case actionTypes.UPDATE_USER_ONLINE: {
      const mention: IdataMention[] = [...state.mention];

      const index = state.mention.findIndex((m: IdataMention) => m.id === action.userID);
      if (index !== -1) {
        mention[index].is_online = action.isOnline;
      }

      return {
        ...state,
        mention
      };
    }
    case actionTypes.GET_LIST_MENTION_ERROR: {
      return { ...state, mention: [] };
    }
    case actionTypes.GET_LIST_FEELING_REQUEST: {
      return { ...state };
    }
    case actionTypes.GET_LIST_FEELING_SUCCESS: {
      return { ...state, feeling: action.payload };
    }
    case actionTypes.GET_LIST_TAG_REQUEST: {
      return { ...state };
    }
    case actionTypes.GET_LIST_TAG_SUCCESS: {
      return { ...state, tag: action.payload.tags };
    }
    case actionTypes.GET_LIST_TAG_ERROR: {
      return { ...state, tag: [], err: action.payload };
    }
    case actionTypes.POST_CREATE_STATUS_REQUEST: {
      return { ...state, loading: true, loadingBtn: true };
    }
    case actionTypes.POST_CREATE_STATUS_SUCCESS: {
      return {
        ...state,
        img: '',
        loading: false,
        isCreateFail: false,
        saveMyStatus: action.payload,
        loadingBtn: false
      };
    }
    case actionTypes.POST_CREATE_STATUS_ERROR: {
      return { ...state, loading: false, isCreateFail: true, loadingBtn: false };
    }
    case actionTypes.UPLOAD_IMAGE_REQUEST: {
      return { ...state };
    }
    case actionTypes.UPLOAD_IMAGE_SUCCESS: {
      return { ...state, img: action.payload };
    }
    case actionTypes.UPLOAD_IMAGE_ERR: {
      return { ...state, img: {} };
    }
    case actionTypes.DELETE_IMAGE: {
      return { ...state, img: {} };
    }
    case actionTypes.GET_LIST_COMMENT: {
      return { ...state, listComment: [] };
    }
    case actionTypes.GET_LIST_COMMENT_SUCESS: {
      return { ...state, listComment: action.payload.comments };
    }
    case actionTypes.GET_LIST_COMMENT_EROR: {
      return { ...state, listComment: [] };
    }
    case actionTypes.POST_LIST_COMMENT_STATUS_SUCCESS: {
      const updateComment = state.data.map((item: IdataStatus) => {
        const newComments = item.newComments || [];
        if (item.id === action.payload.commentable_id) {
          newComments.push(action.payload);
        }
        item.newComments = newComments;
        return item;
      });

      return { ...state, data: updateComment };
    }
    case actionTypes.GET_STATUS_DETAIL: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_STATUS_DETAIL_SUCCESS: {
      return {
        ...state,
        detail: {
          ...action.data,
          newComments: []
        },
        isLoading: false
      };
    }
    case actionTypes.EDIT_NEW_COMMENT_STATUS: {
      return {
        ...state,
        data: action.newEditComment
      };
    }
    case actionTypes.EDIT_STATUS_REQUEST: {
      return { ...state, loadingBtn: true };
    }
    case actionTypes.CREATE_VIDEO_STATUS_REQUEST: {
      return { ...state, loadingBtn: true, loading: true };
    }
    case actionTypes.EDIT_STATUS_SUCCESS: {
      const updateState = state.data.map((item: IdataStatus) => {
        if (item.id === action.payload.id) {
          item = action.payload;
        }
        return item;
      });
      return {
        ...state,
        data: updateState,
        detail: {
          ...action.payload,
          newComments: []
        },
        loadingBtn: false,
        openModal: false
      };
    }
    case actionTypes.LIKE_POST_STATUS_SUCCESS: {
      const { idPost, setLiked, likeCount } = action;
      const updateState = state.data.map((item: IdataStatus) => {
        if (item.id === idPost) {
          return { ...item, like_count: likeCount, review: { ...item.review, is_liked: setLiked } };
        }
        return item;
      });
      return {
        ...state,
        data: updateState,
        detail: {
          ...state.detail,
          review: { ...state.detail.review, is_liked: setLiked },
          like_count: likeCount
        }
      };
    }
    case actionTypes.UPDATE_LIST_COMMENT_STATUS: {
      const { idPost, comments, count } = action;
      const updateState = state.data.map((item: IdataStatus) => {
        if (item.id === idPost) {
          return {
            ...item,
            comment_count: count,
            newComments: [],
            first_comment: comments.at(-1)
          };
        }
        return item;
      });

      return {
        ...state,
        data: updateState
      };
    }
    case actionTypes.GET_STATUS_BY_ID: {
      return { ...state, statusById: {}, getStatusIsLoading: true };
    }
    case actionTypes.GET_STATUS_BY_ID_SUCCESS: {
      return { ...state, statusById: action.payload, getStatusIsLoading: false };
    }
    case actionTypes.OPEN_MODAL: {
      return { ...state, openModal: true, idModal: action.id };
    }
    case actionTypes.CLOSE_MODAL: {
      return { ...state, openModal: false, idModal: 0 };
    }
    case actionTypes.OPEN_PREVIEW_MODAL: {
      return { ...state, idPreviewModal: action.id };
    }
    case actionTypes.CLOSE_PREVIEW_MODAL: {
      return { ...state, idPreviewModal: 0 };
    }
    case actionTypesGiphy.SAVE_GIPHY: {
      return { ...state, imageType: imageTypes.giphy };
    }
    case actionTypesGiphy.CLICK_GIPHY_CLOSE: {
      return { ...state, imageType: '' };
    }
    case actionTypesImage.SAVE_IMG_COMMENT_UPLOAD: {
      return { ...state, imageType: imageTypes.image };
    }
    case actionTypesImage.DESTROY_IMG_COMMENT: {
      return { ...state, imageType: '' };
    }
    case actionTypes.UPLOAD_VIDEO_ERROR: {
      return {
        ...state,
        uploadVideoStatus: {
          ...state.uploadVideoStatus,
          uploading: false,
          uploadError: true,
          success: false,
          fileType: undefined,
          progress: 0
        }
      };
    }
    case actionTypes.UPLOAD_VIDEO_SUCCESS: {
      return {
        ...state,
        uploadVideoStatus: {
          ...uploadVideoStatus, // reset to init state
          success: true,
          fileType: UploadFileType.VIDEO,
          fileURL: action.fileURL
        }
      };
    }
    case actionTypes.UPLOAD_VIDEO_REQUEST: {
      return {
        ...state,
        uploadVideoStatus: {
          ...state.uploadVideoStatus,
          uploading: true,
          uploadError: false,
          fileType: UploadFileType.VIDEO,
          progress: 0,
          success: false,
          fileURL: undefined
        }
      };
    }
    case actionTypes.UPLOAD_PROGRESS_CHANGE: {
      const { percent } = action;
      return {
        ...state,
        uploadVideoStatus: {
          ...state.uploadVideoStatus,
          progress: percent
        }
      };
    }
    case actionTypes.CANCEL_VIDEO_UPLOAD:
    case actionTypes.RESET_UPLOAD_STATUS: {
      return {
        ...state,
        loading: false,
        uploadVideoStatus: uploadVideoStatus
      };
    }
    case actionTypesDetail.READ_POST_DONE: {
      const data = [...state.data];

      const statusIndex = data.findIndex(
        (status: IdataStatus) => String(status.id) === action.data
      );
      if (statusIndex === -1) {
        return state;
      }

      data[statusIndex].review.is_read = true;

      return {
        ...state,
        data
      };
    }
    default:
      return state;
  }
}
