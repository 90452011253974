import { ItreeChartState } from 'src/interface/ITreeNode';
import { actionTypes } from './treeChartAction';

const initialState: ItreeChartState = {
  users: { data: [], isLoading: false },
  chart: { data: undefined, isLoading: false, total_nodes: 0 },
  chartGeneral: { data: undefined, isLoading: false, total_nodes: 0 },
  chartCategories: { data: [], isLoading: false },
  listCharts: { data: [], isLoading: false },
  listNodeChidlren: { data: [], isLoading: false }
};

export function treeChartReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_USERS_REQUEST: {
      return { ...state, users: { ...state.users, isLoading: true } };
    }
    case actionTypes.GET_LIST_USERS_SUCCESS: {
      return { ...state, users: { data: action.payload, isLoading: false } };
    }
    case actionTypes.GET_LIST_USERS_CMS_REQUEST: {
      return { ...state, users: { ...state.users, isLoading: true } };
    }
    case actionTypes.GET_LIST_USERS_CMS_SUCCESS: {
      return { ...state, users: { data: action.payload, isLoading: false } };
    }
    case actionTypes.GET_APPROVER_CHART_REQUEST: {
      return { ...state, chart: { ...state.chart, isLoading: true } };
    }
    case actionTypes.GET_APPROVER_CHART_SUCCESS: {
      return {
        ...state,
        chart: {
          data: action.payload.data,
          total_nodes: action.payload.total_nodes,
          isLoading: false
        }
      };
    }
    case actionTypes.GET_CHART_CATEGORIES_REQUEST: {
      return { ...state, chartCategories: { ...state.chartCategories, isLoading: true } };
    }
    case actionTypes.GET_CHART_CATEGORIES_SUCCESS: {
      return { ...state, chartCategories: { data: action.payload, isLoading: false } };
    }
    case actionTypes.GET_LIST_CHARTS_REQUEST: {
      return { ...state, listCharts: { ...state.listCharts, isLoading: true } };
    }
    case actionTypes.GET_LIST_CHARTS_SUCCESS: {
      return { ...state, listCharts: { data: action.payload, isLoading: false } };
    }
    case actionTypes.GET_CHART_REQUEST: {
      return { ...state, chartGeneral: { ...state.chartGeneral, isLoading: true } };
    }
    case actionTypes.GET_CHART_SUCCESS: {
      return {
        ...state,
        chartGeneral: {
          data: action.payload.data,
          total_nodes: action.payload.total_nodes,
          isLoading: false
        }
      };
    }
    case actionTypes.GET_CHART_DETAIL_SUCCESS: {
      return {
        ...state,
        chart: {
          data: action.payload.data,
          total_nodes: action.payload.total_nodes,
          isLoading: false
        }
      };
    }
    case actionTypes.ACTIVATE_LOADING_REQUEST: {
      return { ...state, chart: { data: undefined, total_nodes: 0, isLoading: true } };
    }
    case actionTypes.DEACTIVATE_LOADING_REQUEST: {
      return {
        ...state,
        chart: { data: state.chart.data, total_nodes: state.chart.total_nodes, isLoading: false }
      };
    }
    case actionTypes.CREATE_NODE_SUCCESS: {
      return { ...state, chart: { data: action.params, isLoading: false } };
    }
    case actionTypes.GET_NODE_CHILDREN_REQUEST: {
      return { ...state, listNodeChidlren: { ...state.listNodeChidlren, isLoading: true } };
    }
    case actionTypes.GET_NODE_CHILDREN_SUCCESS: {
      return { ...state, listNodeChidlren: { data: action.payload, isLoading: false } };
    }
    default:
      return state;
  }
}
