/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const detailAPI = {
  getDataInfor: async (typeDetail: string, idPost: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(
        API_ROUTES.POSTS_INFO_DETAIL(typeDetail, idPost)
      );
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  getListComment: async (idPost: number, pageNumber: number) => {
    const params = {
      commentable_id: idPost,
      commentable_type: 'Post',
      page: pageNumber,
      limit: 20, //number limit of page
      sort_by: 'created_at',
      order_by: 'desc'
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_COMMENTS, { params });
      if (data && data.code === 200) {
        const listCommentPosts: Array<any> = data.data.comments;
        return listCommentPosts.reverse();
      } else return false;
    } catch (error) {
      console.log(error);
    }
  },
  postCommentTextPost: async (idPost: number, text: string, urlGif: string, idImg: number[]) => {
    const dataComment = {
      commentable_id: Number(idPost),
      content: text,
      giphy: urlGif,
      image_ids: idImg,
      file_ids: [],
      hashtags: [],
      mentions: []
      // "point": {
      //   "value": 1
      // }
    };
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_COMMENTS, dataComment);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  putEditComment: async (idCmt: number, dataComment: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(
        API_ROUTES.POSTS_COMMENT_DETAIL(idCmt),
        dataComment
      );
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  likeComment: async (idCmt: number, like: boolean) => {
    try {
      const { data }: AxiosResponse = like
        ? await appAxios.post(API_ROUTES.POSTS_COMMENT_LIKE(idCmt))
        : await appAxios.delete(API_ROUTES.POSTS_COMMENT_LIKE(idCmt));
      return data && data.code === 200 ? data.data || {} : false;
    } catch (error) {
      console.log(error);
    }
  },
  postReadPosts: async (idPost: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.POSTS_READ(idPost));
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  postReactPosts: async (setLiked: boolean, idPost: number) => {
    const url = setLiked
      ? API_ROUTES.POSTS_LIKE_POST(idPost)
      : API_ROUTES.POSTS_DISLIKE_POST(idPost);
    try {
      const { data }: AxiosResponse = await appAxios.post(url);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  postBookmarkPosts: async (setBookmarkPost: boolean, idPost: number) => {
    const url = setBookmarkPost
      ? API_ROUTES.POSTS_BOOKMARK(idPost)
      : API_ROUTES.POSTS_UNBOOKMARK(idPost);
    try {
      const { data }: AxiosResponse = await appAxios.post(url);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  getListInteractive: async (idPosts: number, typeInteractive: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(
        API_ROUTES.POSTS_LIST_INTERACTIVE(idPosts, typeInteractive)
      );
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      console.log(error);
    }
  },
  getListTransaction: async (idPosts: number) => {
    const params = {
      post_id: idPosts
    };
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POINTS_TRANSACTIONS, {
        params
      });
      return data && data.code === 200 ? data.data.transactions : false;
    } catch (error) {
      console.log(error);
    }
  }
};
