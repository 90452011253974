import { AxiosResponse } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { EventAPI } from 'src/api/EventAPI';
import { actionTypes } from './eventAction';

function* requestListDataEvent() {
  try {
    const data: AxiosResponse = yield call(EventAPI.getListDataEvent);
    yield put({
      type: actionTypes.GET_LIST_DATA_EVENT_SUCCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestListDataEvent() {
  yield takeEvery(actionTypes.GET_LIST_DATA_EVENT_REQUEST, requestListDataEvent);
}

export function* eventSaga() {
  yield all([watchRequestListDataEvent()]);
}
