import message from 'antd/lib/message';
import { AxiosResponse } from 'axios';
import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { RewardsApi } from 'src/api/RewardsApi';
import { actionTypes } from './rewardAction';

function* requestListReward() {
  try {
    const data: AxiosResponse = yield call(RewardsApi.getListReward);
    yield put({
      type: actionTypes.GET_LIST_REWARD_SUCCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* requestListCategoryReward() {
  try {
    const data: AxiosResponse = yield call(RewardsApi.getListCategoryReward);
    yield put({
      type: actionTypes.GET_LIST_CATEGORY_REWARD_SUCCESS,
      payload: data.data.categories
    });
  } catch (e) {
    console.log(e);
  }
}

function* requestDataRewardDetail() {
  try {
    const data: AxiosResponse = yield call(RewardsApi.getRewardDetail);
    if (data) {
      yield put({ type: actionTypes.GET_DATA_REWARD_DETAIL_SUCCESS, data });
    } else {
      yield put({ type: actionTypes.GET_DATA_REWARD_DETAIL_FAIL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_DATA_REWARD_DETAIL_FAIL });
  }
}

function* postRewardDetail(action: any) {
  const { id } = action;
  try {
    const data: AxiosResponse = yield call(RewardsApi.postRedeem, id);
    if (data) {
      yield put({ type: actionTypes.POST_REDEEM_REWARD_DETAIL_SUCCESS, payload: id });
      message.success('Redeem success');
    } else {
      yield put({ type: actionTypes.POST_REDEEM_REWARD_DETAIL_FALL });
      message.error('Redeem reward failed');
    }
  } catch (error) {
    yield put({ type: actionTypes.POST_REDEEM_REWARD_DETAIL_FALL });
    message.error('Redeem reward failed');
  }
}

function* filterListReward(action: any) {
  try {
    const selectAllState = (state) => state.rewardsState.listRewards;
    const listRewards = yield select(selectAllState);

    const data = listRewards.originalRewards.filter((item) => {
      const nameIncludes = item.name.toLowerCase().includes(action.payload.toLowerCase());
      const categoryMatches =
        listRewards.type === 0 || item.reward_category_id === listRewards.type;

      return !action.payload.trim() ? categoryMatches : categoryMatches && nameIncludes;
    });
    yield put({
      type: actionTypes.FILTER_LIST_REWARD_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({ type: actionTypes.FILTER_LIST_REWARD_ERROR });
    message.error('Redeem reward failed');
  }
}

function* filterListRewardByCategory(action: any) {
  try {
    const selectAllState = (state) => state.rewardsState.listRewards;
    const listRewards = yield select(selectAllState);
    const newArr = [...listRewards.originalRewards];
    const data =
      Number(action.payload) !== 0
        ? newArr.filter((value) => {
            return value.reward_category_id === action.payload;
          })
        : newArr;
    yield put({
      type: actionTypes.FILTER_CATEGORY_REWARD_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({ type: actionTypes.FILTER_LIST_REWARD_ERROR });
    message.error('Redeem reward failed');
  }
}

function* watchRewardDetail() {
  yield takeLatest(actionTypes.REQUEST_DATA_REWARD_DETAIL, requestDataRewardDetail);
}

function* watchPostRedeem() {
  yield takeLatest(actionTypes.POST_REDEEM_REWARD_DETAIL, postRewardDetail);
}

function* watchRequestListReward() {
  yield takeLatest(actionTypes.GET_LIST_REWARD_REQUEST, requestListReward);
}
function* watchRequesCategorytListReward() {
  yield takeLatest(actionTypes.GET_LIST_CATEGORY_REWARD_REQUEST, requestListCategoryReward);
}

function* watchRequestFilterListReward() {
  yield takeLatest(actionTypes.FILTER_LIST_REWARD_REQUEST, filterListReward);
}
function* watchRequestFilterListRewardByCategory() {
  yield takeLatest(actionTypes.FILTER_CATEGORY_REWARD_REQUEST, filterListRewardByCategory);
}

export function* rewardSaga() {
  yield all([
    watchRequestListReward(),
    watchRewardDetail(),
    watchPostRedeem(),
    watchRequestFilterListReward(),
    watchRequesCategorytListReward(),
    watchRequestFilterListRewardByCategory()
  ]);
}
