import { AxiosError, AxiosResponse } from 'axios';
import API_ROUTES from 'src/common/apiRoutes';
import {
  ApproverParams,
  DeleteNodeParams,
  DetailChartParams,
  AddNodeParams,
  EditNodeParams,
  ListChartsParams,
  ChartParams,
  ListNodeChildrenParams
} from 'src/interface/ITreeNode';
import { appAxios, appAxiosChart } from './appAxios';

export const treeChartAPI = {
  getListUsers: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.TREE_USERS_LIST);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListUsersCms: async (params: { authen_key: string }) => {
    try {
      const { data }: AxiosResponse = await appAxiosChart(params.authen_key).get(
        API_ROUTES.TREE_USERS_CMS_LIST
      );
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getApproverChart: async (params: ApproverParams) => {
    try {
      let res;
      if (params.approver_id) {
        const { data }: AxiosResponse = await appAxios.get(API_ROUTES.APPROVER_CHART, {
          params
        });
        res = data;
      } else {
        const { data }: AxiosResponse = await appAxios.get(API_ROUTES.APPROVER_CHART);
        res = data;
      }
      return res;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getChartCategories: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.CHART_CATEGORIES);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListCharts: async (params: ListChartsParams) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.LIST_CHARTS, { params });
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getChart: async (params: ChartParams) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.CHART(params.id));
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  // CMS API
  getChartDetail: async (params: DetailChartParams & { authen_key: string }) => {
    try {
      const { data }: AxiosResponse = await appAxiosChart(params.authen_key).get(
        API_ROUTES.DETAIL_CHART_CMS(params.id)
      );
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  createNode: async (params: AddNodeParams & { chart_id: number; authen_key: string }) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      if (key !== 'authen_key' && key !== 'id') formData.append(`chart_node[${key}]`, value);
    });
    const res: AxiosResponse = await appAxiosChart(params.authen_key).post(
      API_ROUTES.CREATE_NODE(params.chart_id),
      formData
    );
    return res;
  },
  updateNode: async (params: EditNodeParams & { chart_id: number; authen_key: string }) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
      if (key !== 'authen_key' && key !== 'id' && key !== 'node_id')
        formData.append(`chart_node[${key}]`, value);
    });
    const res: AxiosResponse = await appAxiosChart(params.authen_key).put(
      API_ROUTES.DETAIL_NODE(params.chart_id, params.id),
      formData
    );
    return res;
  },
  deleteNode: async (params: DeleteNodeParams) => {
    const res: AxiosResponse = await appAxiosChart(params.authen_key).delete(
      API_ROUTES.DETAIL_NODE(params.chart_id, params.node_id)
    );
    return res;
  },
  getListNodeChildren: async (params: ListNodeChildrenParams) => {
    const res: AxiosResponse = await appAxiosChart(params.authen_key).get(
      API_ROUTES.LIST_NODE_CHILDREN(params.chart_id, params.node_id)
    );
    return res;
  }
};
