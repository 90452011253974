import { IDataPoll, IPollState } from 'src/interface/IPollState';
import { actionTypes } from './pollAction';

const initialState: IPollState = {
  currentPolls: [],
  isLoading: false,
  isError: false,
  isNewPoll: false,
  votersPopup: {
    isShow: false,
    voters: [],
    title: ''
  }
};

const handleGetPollFail = (currentPolls: IDataPoll[], id: number) => {
  return currentPolls.map((item: IDataPoll) => {
    if (item.id === id) {
      item.fetch_error = true;
    }
    return item;
  });
};

const handleGetPollDetail = (currentPolls: IDataPoll[], poll: IDataPoll) => {
  let updated = false;
  let newPolls = currentPolls.map((item: IDataPoll) => {
    if (!updated && item.id === poll.id) {
      updated = true;
      return poll;
    }
    return item;
  });

  if (!updated) {
    newPolls = [poll, ...currentPolls];
  }

  return newPolls;
};

export function pollReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_CURRENT_POLLS_SUCCESS:
      return {
        ...state,
        currentPolls: action.payload,
        isLoading: false
      };
    case actionTypes.POST_POLL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isNewPoll: true
      };
    case actionTypes.TOGGLE_SHOW_VOTER_POPUP:
      return {
        ...state,
        votersPopup: action.payload
      };
    case actionTypes.GET_POLL_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentPolls: handleGetPollDetail(state.currentPolls, action.payload)
      };
    case actionTypes.POST_POLL:
    case actionTypes.GET_POLL_DETAIL:
    case actionTypes.POST_SUMMIT_POLL:
    case actionTypes.GET_CURRENT_POLLS:
      return {
        ...state,
        isLoading: true,
        isNewPoll: false
      };
    case actionTypes.GET_POLL_DETAIL_ERRROR:
      return {
        ...state,
        currentPolls: handleGetPollFail(state.currentPolls, action.id)
      };
    case actionTypes.POST_POLL_ERROR:
      return {
        ...state,
        isError: true
      };
    default:
      return state;
  }
}
