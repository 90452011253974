import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ProfileApi } from 'src/api/ProfileApi';
import { setError } from 'src/common/commonAction';
import { actionTypes, getOtherProfile } from './OtherProfileAction';

function* requestOtherProfile(action: any) {
  const { id } = action;
  try {
    try {
      const data: AxiosResponse = yield call(ProfileApi.getOtherProfile, id);
      if (data) {
        yield put(getOtherProfile(data));
      }
    } catch (error) {
      yield put(setError());
    }
  } catch (error) {
    console.log(error);
  }
}

function* followProfile(action: any): any {
  try {
    const data: AxiosResponse = yield call(
      ProfileApi.followProfile,
      Number(action.id),
      action.follow
    );
    if (data) {
      yield put({
        type: actionTypes.FOLLOW_PROFILE_SUCCESS
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestOtherProfile() {
  yield takeLatest(actionTypes.REQUEST_OTHER_PROFILE, requestOtherProfile);
}

function* watchFollowProfile() {
  yield takeLatest(actionTypes.FOLLOW_PROFILE, followProfile);
}

export function* otherProfileSaga() {
  yield all([watchRequestOtherProfile(), watchFollowProfile()]);
}
