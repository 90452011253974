export const actionTypes = {
  GET_LIST_ACTIVITY_HISTORY_REQUEST: 'GET_LIST_ACTIVITY_HISTORY_REQUEST',
  GET_LIST_ACTIVITY_HISTORY_SUCCESS: 'GET_LIST_ACTIVITY_HISTORY_SUCCESS',
  GET_LIST_ACTIVITY_HISTORY_ERROR: 'GET_LIST_ACTIVITY_HISTORY_ERROR',
  SET_PAGE_LIST_ACTIVITY_HISTORY: 'SET_PAGE_LIST_ACTIVITY_HISTORY'
};

export const requestListActivityHistory = (page: number, limit: number) => {
  return {
    type: actionTypes.GET_LIST_ACTIVITY_HISTORY_REQUEST,
    page,
    limit
  };
};
