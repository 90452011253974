import { InotificationItem, InotificationState } from 'src/interface/INotificationState';
import { actionTypes } from './notificationAction';

const initialState: InotificationState = {
  notifications: [],
  hasLoadmore: true,
  params: {
    page: 1,
    limit: 10
  },
  count: 0,
  isPending: false,
  isLoadingMore: false,
  unread_num: 0
};

export function notificationReducer(state: InotificationState = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_PENDING: {
      return {
        ...state,
        isPending: true,
        isLoadingMore: true
      };
    }
    case actionTypes.GET_NOTIFICATION_SUCCESS: {
      const { data, hasLoadmore, params, unread_num, count } = action;

      return {
        ...state,
        notifications: data,
        params: {
          page: params.page,
          limit: params.limit
        },
        hasLoadmore,
        unread_num,
        count,
        isPending: false,
        isLoadingMore: false
      };
    }
    case actionTypes.READ_NOTIFICATION_SUCCESS: {
      const { id, unread_num } = action;
      const newData = state.notifications.map((notification: InotificationItem) => {
        if (notification.id === id) {
          return {
            ...notification,
            is_read: true
          };
        }
        return notification;
      });
      return {
        ...state,
        notifications: newData,
        unread_num
      };
    }
    case actionTypes.READ_ALL_NOTIFICATION_SUCCESS: {
      const newData = state.notifications.map((notification: InotificationItem) => {
        return {
          ...notification,
          is_read: true
        };
      });
      return {
        ...state,
        notifications: newData,
        unread_num: 0
      };
    }
    default:
      return state;
  }
}
