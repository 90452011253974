/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse, AxiosError } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const heatmapApi = {
  postHeatmap: async (params: {
    date: string;
    items: {
      content: string;
      hours: number | null;
      project_id: string | number;
    }[];
  }) => {
    try {
      const { data }: AxiosResponse = await appAxios.post(API_ROUTES.HEATMAP_RECORDS, params);
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  putHeatmap: async (params: {
    content: string;
    hours: number | null;
    date: string;
    project_id: string | number;
    hm_id: number;
  }) => {
    try {
      const { data }: AxiosResponse = await appAxios.put(
        API_ROUTES.HEATMAP_RECORD_DETAIL(params.hm_id),
        params
      );
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  deleteHeatmap: async (params: { project_id: number }) => {
    try {
      const { data }: AxiosResponse = await appAxios.delete(
        API_ROUTES.HEATMAP_RECORD_DETAIL(params.project_id)
      );
      return data;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  }
};
