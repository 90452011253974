import { IdataReview } from 'src/interface/IStatusState';

export interface IPointRewardState {
  data: IDataPointReward[];
  isLoadingListPointReward: boolean;
  page: number;
  limit: number;
  count: number;
}

export interface IdataUser {
  id: number;
  avatar_url: string;
  username: string;
  name: string;
  team: string;
}

export interface IDataPointReward {
  id: number;
  sender: IdataUser;
  receiver: IdataUser;
  point: number;
  object_type: TransactionObjectType;
  object_id: string;
  url_object_type: string;
  url_object_id: number;
  object_content: string;
  object_review: IdataReview;
  created_at: string;
  updated_at: string;
  transaction_type: TransactionType;
  rejecter: IRejecterData;
}

export interface IPointRewardResponse {
  transactions: IDataPointReward[];
  count: number;
  page: number;
  limit: number;
}

export enum TransactionObjectType {
  Status = 'Status',
  Post = 'Post',
  Comment = 'Comment',
  Event = 'Event',
  Reward = 'Reward',
  Chat = 'Chat'
}

export enum TransactionType {
  Denied = 5
}

export interface IRejecterData {
  id: number;
  avatar_url: string;
  mattermost_name: string;
  name: string;
  team: string;
}
