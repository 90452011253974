import { Source } from 'src/components/ChatGPT/Presentation/ChatMessageBubble';

export interface Iconversation {
  id: string;
  title: string;
  is_error: boolean;
  updated_at: string;
  created_at: string;
  model: string;
}

export enum RATING {
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

export enum AUTHOR {
  USER = 'user',
  AI = 'ai'
}

export enum REACTION {
  LIKE = 'LIKE',
  UNLIKE = 'UNLIKE'
}

export interface Ifeedback {
  id?: number;
  feedback?: string;
  tags?: string[];
  rating: RATING;
}

export interface Imodel {
  name: string;
  model: string;
}

export interface Imessage {
  id: string;
  content: string;
  author: 'user' | 'ai';
  sources?: Source[];
  runId?: string;
  name?: string;
  createdAt?: Date;
  function_call?: { name: string };
  feedback?: Ifeedback;
}

export interface IchatGptState {
  conversations: {
    data: Iconversation[];
    isLoading: boolean;
  };
  messages: {
    data: Imessage[];
    isLoading: boolean;
  };
}
