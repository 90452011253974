import { IitemComment } from 'src/interface/IDetailState';

export const actionTypes = {
  GET_LIST_COMMENT_STATUS: 'GET_LIST_COMMENT_STATUS',
  GET_LIST_INITIAL_COMMENT_STATUS_SUCCESS: 'GET_LIST_INITIAL_COMMENT_STATUS_SUCCESS',
  GET_LIST_COMMENT_STATUS_SUCCESS: 'GET_LIST_COMMENT_STATUS_SUCCESS',
  GET_LIST_COMMENT_STATUS_FALL: 'GET_LIST_COMMENT_STATUS_FALL',
  EDIT_COMMENT_STATUS: 'EDIT_COMMENT_STATUS',
  POST_NEW_COMMENT_STATUS_DETAIL: 'POST_NEW_COMMENT_STATUS_DETAIL'
};
export const setNewEditCommentStatus = (id: string, comments: IitemComment) => ({
  type: actionTypes.EDIT_COMMENT_STATUS,
  id,
  comments
});
