import { AxiosResponse } from 'axios';
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { CreateApi } from 'src/api/CreateApi';
import { actionTypes } from './categoryTabActions';

function* requestOrganizerMentions() {
  try {
    const mentions: AxiosResponse = yield call(CreateApi.mentions);
    if (mentions) {
      const coefficientPoint: AxiosResponse = yield call(CreateApi.eventCoefficientPoints);
      if (coefficientPoint) {
        yield put({
          type: actionTypes.GET_ORGANIZER_MENTION_SUCCESS,
          mentions,
          coefficientPoint
        });
      } else {
        yield put({ type: actionTypes.GET_ORGANIZER_MENTION_FALL });
      }
    } else {
      yield put({ type: actionTypes.GET_ORGANIZER_MENTION_FALL });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_ORGANIZER_MENTION_FALL });
  }
}

function* pushListMentions(action: any) {
  const { organizer, organizersList, tab } = action;
  yield put({ type: actionTypes.SAVE_LIST_MENTION, organizersList, organizer, tab });
}

function* pushDateFromTo(action: any) {
  const { dataFromTo } = action;
  yield put({ type: actionTypes.SAVE_DATE_FROM_TO, dataFromTo });
}

function* pushActiveTab(action: any) {
  const { activeTab } = action;
  yield put({ type: actionTypes.SAVE_ACTIVE_TAB, activeTab });
}

function* watchDateFromTo() {
  yield takeLatest(actionTypes.PUSH_DATE_FROM_TO, pushDateFromTo);
}

function* watchPushMentions() {
  yield takeLatest(actionTypes.PUSH_LIST_MENTION, pushListMentions);
}

function* watchPushActiveTab() {
  yield takeLatest(actionTypes.PUSH_ACTIVE_TAB, pushActiveTab);
}

function* watchRequestOrganizerMentions() {
  yield takeLatest(actionTypes.REQUEST_ORGANIZER_MENTION, requestOrganizerMentions);
}

export function* categoryTabSaga() {
  yield all([
    watchRequestOrganizerMentions(),
    watchPushMentions(),
    watchDateFromTo(),
    watchPushActiveTab()
  ]);
}
