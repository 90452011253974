import { actionTypes } from './lateOffDayAction';

const initialState = {
  isLoading: false,
  actionSuccess: true,
  data: []
};

export function lateOffDayReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_DATA_LATE_OFF_DAY_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case actionTypes.GET_LIST_DATA_LATE_OFF_DAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data.items,
        isLoading: false,
        actionSuccess: true
      };
    }
    default:
      return state;
  }
}
