import { AxiosResponse } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const TopApi = {
  getTopGiven: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_POINT_GIVEN);
      return data.code === 200 && data.data.users ? data.data.users : false;
    } catch (error) {
      // throw error;
    }
  },
  getTopReceivers: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_POINT_RECEIVERS);
      return data.code === 200 && data.data.users ? data.data.users : false;
    } catch (error) {
      // throw error;
    }
  },
  getTopHashTag: async (page = 1, limit = 20) => {
    try {
      const params = {
        page,
        limit
      };
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_HASHTAG, { params });
      return data.code === 200 && data.data.tags ? data.data.tags : false;
    } catch (error) {
      // throw error;
    }
  },
  getUsers: async () => {
    try {
      const params = {
        limit: 1000
      };
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.USERS_LIST, { params });
      return data || {};
    } catch (error) {
      // throw error;
    }
  }
};
