import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';
import { activitiesReducer } from 'src/activities/activitiesReducer';
import { activityHistoryReducer } from 'src/activities/activityHistory/activityHistoryReducer';
import { announcementsReducer } from 'src/activities/announcements/announcementsReducer';
import { createReducer } from 'src/activities/create/createReducer';
import { searchheaderReducer } from 'src/activities/header/searchheaderReducer';
import { newsReducer } from 'src/activities/news/newsReducer';
import { detailReducer } from 'src/activities/NewsEventDetail/DetailReducer';
import { pointRewardReducer } from 'src/activities/pointReward/pointRewardReducer';
import { pollReducer } from 'src/activities/poll/pollReducer';
import { statusReducer } from 'src/activities/status/statusReducer';
import { treeChartReducer } from 'src/activities/treeChart/treeChartReducer';
import { chatGptReducer } from 'src/chatgpt/chatGptReducer';
import commonReducer from 'src/common/commonReducer';
import { listUserLikeReducer } from 'src/components/CardLike/listUserLikeReducer';
import { categoryTabReducer } from 'src/components/CategoryTab/categoryTabReducer';
import { chartReducer } from 'src/components/Chart/chartReducer';
import { commentStatusReducer } from 'src/components/CommentStatus/commentStatusReducer';
import { eventReducer } from 'src/components/events/eventReducer';
import { giphyReducer } from 'src/components/Giphy/giphyReducer';
import { headerReducer } from 'src/components/Header/headerReducer';
import formReducer from 'src/components/Heatmap/form/formReducer';
import { historyReducer } from 'src/components/Heatmap/history/historyReducer';
import { imageCommentReducer } from 'src/components/ImageComment/imageCommentReducer';
import { lateOffDayReducer } from 'src/components/LateOffDay/lateOffDayReducer';
import { signinReducer } from 'src/components/signin/signinReducer';
import { hashtagRecommentReducer } from 'src/components/SlideHashTag/recommentHashtagReducer';
import { videoCommentReducer } from 'src/components/VideoComment/videoCommentReducer';
import { dashboardReducer } from 'src/dashboard/dashboardReducer';
import { forgotPasswordReducer } from 'src/forgotPassword/forgotPasswordReducer';
import { notificationReducer } from 'src/notifications/notificationReducer';
import { portalReducer } from 'src/portal/portalReducer';
import { otherProfileReducer } from 'src/profile/OtherProfile/OtherProfileReducer';
import { chartProfileReducer } from 'src/profile/ProfileReducer';
import { resetPasswordReducer } from 'src/resetPassword/resetPasswordReducer';
import { rewardsReducer } from 'src/reward/rewardReducer';

export const appReducer = combineReducers({
  commonState: commonReducer,
  signinState: signinReducer,
  headerState: headerReducer,
  eventState: eventReducer,
  notificationState: notificationReducer,
  formState: formReducer,
  heatmapState: chartReducer,
  historyState: historyReducer,
  chartProfileState: chartProfileReducer,
  forgotPasswordState: forgotPasswordReducer,
  resetPasswordState: resetPasswordReducer,
  activitiesState: activitiesReducer,
  announcementsState: announcementsReducer,
  searchheaderState: searchheaderReducer,
  hashtagRecommentState: hashtagRecommentReducer,
  statusState: statusReducer,
  detailState: detailReducer,
  imageCmtState: imageCommentReducer,
  giphyState: giphyReducer,
  commentStatusState: commentStatusReducer,
  newsState: newsReducer,
  createnewState: createReducer,
  categoryState: categoryTabReducer,
  pointRewardState: pointRewardReducer,
  videoCommentState: videoCommentReducer,
  pollState: pollReducer,
  listUserLikeState: listUserLikeReducer,
  rewardsState: rewardsReducer,
  activityHistoryState: activityHistoryReducer,
  lateOffDayState: lateOffDayReducer,
  newestPostsState: dashboardReducer,
  otherProfileState: otherProfileReducer,
  portalState: portalReducer,
  chatGptState: chatGptReducer,
  treeChartState: treeChartReducer,
  modal
});
