import { IloginState } from 'src/interface/ILoginState';
import { actionTypes } from './signinAction';

const initialState: IloginState = {
  authen: '',
  isLoading: true
};

export const signinReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.POST_AUTH:
      return { ...state, isLoading: true };
    case actionTypes.LOGIN_SUCCESS:
      return { ...state, authen: action.auth_token, isLoading: false };
    case actionTypes.LOGIN_FAIL:
      return { ...state, authen: 'false', isLoading: false };

    default:
      return state;
  }
};
