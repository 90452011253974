export const actionTypes = {
  GET_LIST_REWARD_REQUEST: '@@GET_LIST_REWARD_REQUEST',
  GET_LIST_REWARD_SUCCESS: '@@GET_LIST_REWARD_SUCCESS',
  GET_LIST_REWARD_ERROR: '@@GET_LIST_REWARD_ERROR',

  REQUEST_DATA_REWARD_DETAIL: 'REQUEST_DATA_REWARD_DETAIL',
  GET_DATA_REWARD_DETAIL_SUCCESS: 'GET_DATA_REWARD_DETAIL_SUCCESS',
  GET_DATA_REWARD_DETAIL_FAIL: 'GET_DATA_REWARD_DETAIL_FAIL',
  POST_REDEEM_REWARD_DETAIL: 'POST_REDEEM_REWARD_DETAIL',
  POST_REDEEM_REWARD_DETAIL_SUCCESS: 'POST_REDEEM_REWARD_DETAIL_SUCCESS',
  POST_REDEEM_REWARD_DETAIL_FALL: 'POST_REDEEM_REWARD_DETAIL_FALL',

  FILTER_LIST_REWARD_REQUEST: '@@FILTER_LIST_REWARD_REQUEST',
  FILTER_LIST_REWARD_SUCCESS: '@@FILTER_LIST_REWARD_SUCCESS',
  FILTER_LIST_REWARD_ERROR: '@@FILTER_LIST_REWARD_ERROR',

  GET_LIST_CATEGORY_REWARD_REQUEST: '@@GET_LIST_CATEGORY_REWARD_REQUEST',
  GET_LIST_CATEGORY_REWARD_SUCCESS: '@@GET_LIST_CATEGORY_REWARD_SUCCESS',
  GET_LIST_CATEGORY_REWARD_ERROR: '@@GET_LIST_CATEGORY_REWARD_ERROR',

  FILTER_CATEGORY_REWARD_REQUEST: '@@FILTER_CATEGORY_REWARD_REQUEST',
  FILTER_CATEGORY_REWARD_SUCCESS: '@@FILTER_CATEGORY_REWARD_SUCCESS',
  FILTER_CATEGORY_REWARD_ERROR: '@@FILTER_CATEGORY_REWARD_ERROR',

  CHANGE_FILTER_CATEGORY: '@@CHANGE_FILTER_CATEGORY'
};

export const requestListReward = () => ({
  type: actionTypes.GET_LIST_REWARD_REQUEST
});

export const requestFilterListReward = (payload: any) => ({
  type: actionTypes.FILTER_LIST_REWARD_REQUEST,
  payload
});
export const requestListCategoryReward = (payload: any) => ({
  type: actionTypes.GET_LIST_CATEGORY_REWARD_REQUEST,
  payload
});
