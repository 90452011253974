import { AxiosResponse, AxiosError } from 'axios';
import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';

export const InteractiveApi = {
  getListUserLike: async (id: number) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_LIKE_LIST(id));
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  },
  getListHashTagRecomment: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(API_ROUTES.POSTS_TAG_SUGGESTION);
      return data && data.code === 200 ? data.data : false;
    } catch (error) {
      const err: AxiosError = error;
      throw err.response;
    }
  }
};
