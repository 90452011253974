import { actionTypes as actionTypeComment } from 'src/activities/NewsEventDetail/DetailAction';
import { actionTypes as actionTypeStatus } from 'src/activities/status/statusAction';
import {
  IPointRewardState,
  IDataPointReward,
  TransactionObjectType
} from 'src/interface/IPointRewardState';
import { actionTypes } from './pointRewardAction';

const initialState: IPointRewardState = {
  data: [],
  isLoadingListPointReward: false,
  page: 1,
  limit: 1,
  count: 1
};

export function pointRewardReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_POINT_REWARD_REQUEST: {
      return {
        ...state,
        isLoadingListPointReward: true
      };
    }
    case actionTypes.GET_LIST_POINT_REWARD_SUCESS: {
      let data: IDataPointReward[] = [];
      if (action.payload.page === 1) {
        data = [];
      } else {
        data = [...state.data];
      }

      data.push(...action.payload.transactions);

      return {
        ...state,
        data,
        isLoadingListPointReward: false,
        count: action.payload.count,
        page: action.payload.page,
        limit: action.payload.limit
      };
    }
    case actionTypes.GET_LIST_POINT_REWARD_ERROR: {
      return {
        ...state,
        data: [],
        isLoadingListStatus: false
      };
    }
    case actionTypes.SET_PAGE_LIST_POINT_REWARD: {
      return {
        ...state,
        page: action.payload
      };
    }
    case actionTypeStatus.LIKE_POST_STATUS_SUCCESS: {
      const { idPost, setLiked } = action;

      const data: IDataPointReward[] = [...state.data];
      const index = data.findIndex((t: IDataPointReward) => {
        return (
          (t.object_type === TransactionObjectType.Post ||
            t.object_type === TransactionObjectType.Status) &&
          t.object_id === idPost
        );
      });

      if (index === -1) {
        return state;
      }

      data[index].object_review.is_liked = setLiked;

      return {
        ...state,
        data
      };
    }
    case actionTypeComment.LIKE_COMMENT_SUCCESS: {
      const { id, like } = action;

      const data: IDataPointReward[] = [...state.data];
      const index = data.findIndex((t: IDataPointReward) => {
        return t.object_type === TransactionObjectType.Comment && t.object_id === id;
      });

      if (index === -1) {
        return state;
      }

      data[index] = { ...data[index] };

      if (data[index].object_review) {
        data[index].object_review.is_liked = like;
      } else {
        data[index].object_review = {
          is_bookmarked: false,
          is_clicked: false,
          is_followed: false,
          is_liked: true,
          is_read: false
        };
      }

      return {
        ...state,
        data
      };
    }
    default:
      return state;
  }
}
