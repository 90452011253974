import { appAxios } from 'src/api/appAxios';
import API_ROUTES from 'src/common/apiRoutes';
import { IformForgotPassword } from 'src/interface/IForgotPasswordState';
import { IformResetPassword } from 'src/interface/IResetPasswordState';

export const ForgotPasswordApi = {
  postForgotPassword: async (formData: IformForgotPassword) => {
    try {
      const data = await appAxios.post(API_ROUTES.FORGOT_PASSWORD, formData);
      return data ? data : false;
    } catch (error) {
      // throw error;
    }
  },
  postResetPassword: async (formData: IformResetPassword) => {
    try {
      const data = await appAxios.post(API_ROUTES.RESET_PASSWORD, formData);
      return data ? data : false;
    } catch (error) {
      // throw error;
    }
  }
};
