import { actionTypes as actionTypesCommentDetail } from 'src/activities/status/statusAction';
import { IcommentStatusState } from 'src/interface/ICommentStatusState';
import { IitemComment } from 'src/interface/IDetailState';
import { actionTypes } from './commentStatusAction';

const initialState: IcommentStatusState = {
  dataComment: {},
  isFall: false,
  isLoading: false,
  isEdit: false,
  isLoadingCreateComment: false,
  prevCreatedEditorId: 0
};
export function commentStatusReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_COMMENT_STATUS: {
      return {
        ...state,
        isLoading: true,
        isEdit: false
      };
    }
    case actionTypes.GET_LIST_COMMENT_STATUS_FALL: {
      return {
        ...state,
        isFall: true,
        isLoading: false,
        isEdit: false
      };
    }
    case actionTypes.GET_LIST_INITIAL_COMMENT_STATUS_SUCCESS: {
      const newComments = { ...state.dataComment };
      newComments[action.payload.id] = action.payload;
      return {
        ...state,
        dataComment: newComments,
        isFall: false,
        isLoading: false,
        isEdit: false
      };
    }
    case actionTypes.GET_LIST_COMMENT_STATUS_SUCCESS: {
      const newComments = state.dataComment;
      newComments[action.payload.id].comments = [
        ...action.payload.comments,
        ...state.dataComment[action.payload.id].comments
      ];
      newComments[action.payload.id].page = action.payload.page;
      newComments[action.payload.id].limit = action.payload.limit;
      newComments[action.payload.id].count = action.payload.count;
      return {
        ...state,
        dataComment: newComments,
        isFall: false,
        isLoading: false,
        isEdit: false
      };
    }
    case actionTypesCommentDetail.POST_LIST_COMMENT_DETAIL_SUCESS: {
      const newComments = { ...state.dataComment };
      const comments = newComments[action.payload.commentable_id]
        ? newComments[action.payload.commentable_id].comments
        : [];
      const existComment = comments.find(
        (comment: IitemComment) => comment.id === action.payload.id
      );
      let newDetailComment: IitemComment[] = [];
      if (!existComment || existComment === undefined) {
        newDetailComment = [...comments, action.payload];
      }
      if (newComments[action.payload.commentable_id]) {
        newComments[action.payload.commentable_id].comments = newDetailComment;
        newComments[action.payload.commentable_id].count++;
      }
      return {
        ...state,
        dataComment: newComments,
        isLoading: false,
        isEdit: false,
        isLoadingCreateComment: false,
        isFall: false,
        prevCreatedEditorId: action.payload.commentable_id
      };
    }
    case actionTypes.EDIT_COMMENT_STATUS: {
      const newComments = { ...state.dataComment };

      newComments[action.id].comments = action.comments;
      return {
        ...state,
        dataComment: newComments,
        isEdit: true
      };
    }
    case actionTypes.POST_NEW_COMMENT_STATUS_DETAIL: {
      const newComments = state.dataComment;
      const comments = newComments[action.id] ? newComments[action.id].comments : [];
      const existComment = comments.find(
        (comment: IitemComment) => comment.id === action.payload.id
      );
      let newDetailComment: IitemComment[] = [];
      if (!existComment || existComment === undefined) {
        newDetailComment = [...comments, action.payload];
      }
      newComments[action.id].comments = newDetailComment;
      newComments[action.id].count++;
      return {
        ...state,
        dataComment: newComments,
        isLoading: false,
        isEdit: false
      };
    }
    case actionTypesCommentDetail.POST_LIST_COMMENT_STATUS_REQUEST: {
      return { ...state, isLoadingCreateComment: true, isFall: false };
    }
    case actionTypesCommentDetail.POST_LIST_COMMENT_STATUS_SUCCESS: {
      return {
        ...state,
        isLoadingCreateComment: false,
        isFall: false,
        prevCreatedEditorId: action.payload.commentable_id
      };
    }
    case actionTypesCommentDetail.POST_LIST_COMMENT_STATUS_ERROR: {
      return { ...state, isLoadingCreateComment: false, isFall: true };
    }
    default:
      return state;
  }
}
