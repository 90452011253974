import { AxiosResponse } from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { DashboardAPI } from 'src/api/DashboardAPI';
import { actionTypes } from './dashboardAction';

function* requestNewestPosts() {
  try {
    const data: AxiosResponse = yield call(DashboardAPI.getNewestPost);
    yield put({
      type: actionTypes.GET_NEWEST_POSTS_SUCCESS,
      payload: data
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchRequestNewestPosts() {
  yield takeEvery(actionTypes.GET_NEWEST_POSTS_REQUEST, requestNewestPosts);
}

export function* dashboardSaga() {
  yield all([watchRequestNewestPosts()]);
}
