import { AxiosError, AxiosResponse } from 'axios';
import { createWrapper, MakeStore } from 'next-redux-wrapper';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { appAxios } from 'src/api/appAxios';
import { appReducer } from 'src/app/appReducer';
import { appSaga } from 'src/app/appSaga';
import { IAppState } from 'src/interface/IAppState';
/* eslint-disable @typescript-eslint/no-var-requires */
// import * as Sentry from "@sentry/react";
// import * as Sentry from '@sentry/node';

// axios middleware
const createAxiosMiddleware = (axios: any) => () => {
  axios.interceptors.response.use(
    function (response: AxiosResponse) {
      return response;
    },
    async function (error: AxiosError) {
      if (error && error.response && error.response.status) {
        const { status } = error.response;
        if (status === 401) {
          localStorage && localStorage.removeItem('token');
          localStorage && localStorage.removeItem('user_id');
        }
      }

      // Sentry.setExtra('error', error);
      // Sentry.captureException(error);

      // await Sentry.flush(2000);

      return Promise.reject(error);
    }
  );

  return (next: any) => (action: any) => next(action);
};

// Build app middleware
const axiosMiddleware = createAxiosMiddleware(appAxios);
// const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

// const reduxCompose = compose<any, any>(
//   bindMiddleware([sagaMiddleware, axiosMiddleware]),
//   sentryReduxEnhancer
// );

// Build app store
// const appStore = createStore(appReducer, reduxCompose);

// Run saga middleware

const makeStore: MakeStore<IAppState> = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(appReducer, bindMiddleware([sagaMiddleware, axiosMiddleware])) as any;

  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(appSaga);
  };

  store.runSagaTask();
  return store;
};

export default createWrapper<IAppState>(makeStore, {
  debug: process.env.NODE_ENV !== 'production'
});
