import { actionTypes } from 'src/components/events/eventAction';

const initialState = {
  isLoadingListDataEvent: false,
  actionSuccess: true,
  listDataEvent: []
};

export function eventReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_LIST_DATA_EVENT_REQUEST: {
      return { ...state, isLoadingListDataEvent: true };
    }
    case actionTypes.GET_LIST_DATA_EVENT_SUCCESS: {
      return {
        ...state,
        listDataEvent: action.payload.data.items,
        isLoadingListRewards: false,
        actionSuccess: true
      };
    }
    default:
      return state;
  }
}
